import React, {Component} from 'react';
import {Container, Text, SubText, Wrapper} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class SuccessMessage extends Component {

    render() {
        const {message, icon, subText, applicationContext, children, iconName, color} = this.props;

        return (
            <Container>
                <Wrapper color={color}>
                    {icon && <React.Fragment><FontAwesomeIcon icon={iconName??"check-circle"} /><br/></React.Fragment>}
                    {message && <Text className={"message"}>{applicationContext.translator(message)}</Text>}
                    {subText && <SubText className={"sub-message"}>{applicationContext.translator(subText)}</SubText>}
                    {children}
                </Wrapper>

            </Container>
        );
    }
}
SuccessMessage.propTypes = {
    icon: PropTypes.bool,
    iconName: PropTypes.string,
    message: PropTypes.string,
    subText: PropTypes.string,
    color: PropTypes.string
}
export default withApplicationContext(SuccessMessage);