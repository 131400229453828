import React, {Component} from 'react';
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import {BlockText, FormSubTitle, FormTitle} from "../style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button/Button";
import {NavLink} from "react-router-dom";
import {ROUTE_LOGIN} from "../../../routes/routes";
import {Formik} from "formik";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {msisdn} from "../../../helpers";
import PropTypes from "prop-types";

class EnterMobileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: ""
        }
    }
    render() {
        const {applicationContext, onSubmit, submitting, submitLabel} = this.props;
        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                initialValues={{
                    mobile: this.state.mobile
                }}
                validationSchema={Yup.object().shape({
                    mobile: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                        .label(applicationContext.translator("Mobile number"))
                        .test('start', applicationContext.translator("Mobile number must start with 9"), val => val.startsWith("9"))
                })}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) =>
                    (
                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                            <FormTitle>{applicationContext.translator("Enter your mobile number and enter the code will send to you by sms.")}</FormTitle>
                            <FormSubTitle red={true}>
                                {applicationContext.translator("Please enter mobile number without zero.")}
                            </FormSubTitle>
                            <InputBox type="text"
                                      name={"mobile"}
                                      onChange={handleChange}
                                      placeholder={"9121234567"}
                                      value={values.mobile}
                                      save={"off"}
                                      maxLength={10}
                                      onlyNumber={true}
                                      center={true}
                                      focus={true}
                                      errors={errors}
                                      touched={touched}
                            />
                            <Form.Group className={"text-center"}>
                                <Button color={"primary"} fullWidth={true} size={3} submitting={submitting} radius={10} className={"btn btn-account"} type={"submit"}>{submitLabel??applicationContext.translator("Send code")}</Button>
                            </Form.Group>


                        </Form>
                    )}
            </Formik>
        );
    }
}
EnterMobileForm.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string
}
export default withApplicationContext(EnterMobileForm);