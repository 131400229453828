import React, {Component} from 'react';
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import {BlockText, FormSubTitle, FormTitle} from "../style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button/Button";
import {NavLink} from "react-router-dom";
import {ROUTE_LOGIN} from "../../../routes/routes";
import {Formik} from "formik";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {msisdn} from "../../../helpers";
import PropTypes from "prop-types";
import InputNumberBox from "../../../components/ui/InputNumberBox/InputNumberBox";

class EnterMobileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: ""
        }
    }
    render() {
        const {applicationContext, onSubmit, submitting, submitLabel} = this.props;
        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                initialValues={{
                    code: this.state.code
                }}
                validationSchema={Yup.object().shape({
                    code: Yup.number().required(applicationContext.translator("You must enter your ${path}"))
                        .label(applicationContext.translator("Verification Code"))
                        .test('len', applicationContext.translator("${path} must be exactly 6 characters"), val => val.toString().length === 6)
                })}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) =>
                    (
                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                            <FormTitle>
                                {applicationContext.translator("Enter sent the code to your mobile number.")}
                            </FormTitle>
                            <InputNumberBox
                                name={"code"}
                                handleChange={(value) => setFieldValue('code', value)}
                                count={6}
                                errors={errors}
                                touched={touched}
                            />

                            <Form.Group className={"text-center"}>
                                <Button color={"primary"} fullWidth={true} size={3} submitting={submitting} radius={10} type={"submit"}>{submitLabel??applicationContext.translator("OK")}</Button>
                            </Form.Group>


                        </Form>
                    )}
            </Formik>
        );
    }
}
EnterMobileForm.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string
}
export default withApplicationContext(EnterMobileForm);