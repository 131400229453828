import styled from "styled-components";
import {Col, Form, Button} from "react-bootstrap"
export const InputContainer = styled(Form.Control)`
  padding: ${(props) => !props.padding?`0.8em 1.3em;`:`${props.padding};`}
  ${(props) => props.floatLabel && `
        padding: 1.2em 1.3em .9em 1.3em;
  `}
  border-radius: var(--form-element-border-radius);
  background: var(--form-element-background);
  color: var(--form-element-text-color);
  border-color: var(--form-element-border);
  &:focus{
    ${(props) => !props.strength?`
      box-shadow: 0 0 0 0.25rem var(--form-element-focus-shadow) !important;
    `:`
      box-shadow: none !important;
    `}
  }
  &:active, &:focus{
    ${(props) => !props.strength?`
      border:1px solid  var(--color-secondary) !important;
    `:`
      border-color: #ced4da;
    `}
    color: var(--form-element-text-color) !important;
    background: var(--form-element-background) !important;
    
  }
  &:disabled{
    background: var(--form-element-background-disabled) !important;
    color: var(--form-element-text-color-disabled) !important;
  }
  &.enInput{
    direction: ltr;
    text-align: left;
    ${(props) => ((props.name === "password" || props.name === "password_confirmation")) && (
      props.dir === "rtl" ?`padding-left: 3.5em !important;`:`padding-right: 3.5em !important;`
    )}
  }
  ${(props) => props.center && `
        text-align: center !important;
  `}
  ${(props) => props.fontSize && `
        font-size: ${props.fontSize} !important;
  `}
   ${(props) => props.fontWeight && `
        font-weight: ${props.fontWeight} !important;
  `}
  ${(props) => props.buttonAlign === "right" && `
        padding-right: 140px;
  `}
  ${(props) => props.buttonAlign === "left" && `
        padding-left: 140px;
  `}
  ${(props) => props.strength && `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
  `}
  ${(props) => props.strength && props.strengthColor && `
        border-color: ${props.strengthColor} !important; 
  `}
  width: 100%;
  
`;
export const InputCol = styled(Col)`
  position: relative;
`
export const Wrapper = styled.div`
  position: relative;
  ${(props) => props.line && `
        display: flex;
        justify-content: space-between;
        align-items: center;      
        & .form-label{
            min-width: 100px;
            margin-bottom: 0;
        }
  `}
  ${(props) => props.padding && ` padding:${props.padding} !important; `}
`
export const Label = styled(Form.Label)`
  font-weight: 500;
  color: var(--form-element-text-color);
  ${(props) => props.floatLabel && `
          position: absolute;
          top: -0.8em;
          right: 1em;
          background: var(--form-element-background);
          padding: 0.2em 0.5em;
          text-align: right;
          border-radius: 5px;
          margin-bottom: 0;
          color: var(--form-element-text-color-2);
  `}
  
`;
export const InputGroup = styled(Form.Group)`
  margin-bottom: 1em;
  ${(props) => props.margin && `
        margin: ${props.margin} !important;
  `}
  ${(props) => props.floatLabel && `
        margin-bottom: 1.5em !important;
  `}
  ${(props) => props.padding && `
        padding: ${props.padding} !important;
  `}
  
  
`

export const ShowPassword = styled.div`
  position: absolute;
  top: 0.65em;
  left: 1.15em;
  font-size: 1.2em;
  color: var(--form-element-text-color);
  cursor: pointer;
  &:hover{
    color: var(--color-primary);
    transition: all ease-in-out .2s;
  }
  ${(props) => props.dir === "ltr" && `
    left: auto;
    right: 1em;
  `}
  ${(props) => props.label && `
    top: 2.33em;
  `}
  
`
export const InputError = styled(Form.Text)`
    color: #dc3545;
    margin: 0em 0.3em;
    ${(props) => props.hidden && `
        display: none;
    `}
    ${(props) => props.line && `
        padding-right: 100px;
    `}
    ${(props) => props.hide && `
        visibility: hidden;
    `}
    

`
export const ChangeButton = styled(Button)`
  position: absolute;
  top: 0em;
  padding: 0.8em 1.3em;
  max-width: 140px;
  height: 46px;
  overflow: hidden;
  background: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.label && `
    top: 2em;
  `}
  &:hover{
    background: #fff;
    border-color: var(--color-secondary);
    color: var(--color-secondary);
  }
  &:focus{
    box-shadow: 0 0 0 0.25rem rgb(241 204 1 / 25%) !important;
  }
  &:active, &:focus{
    border:1px solid  var(--color-secondary) !important;
    color: #fff !important;;
    background: var(--color-secondary) !important;;
  }
  &:disabled{
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    opacity: var(--bs-btn-disabled-opacity);
  }
  ${(props) => props.align && props.align === "right"?`
        right: 0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    `:`
        left: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    `}
  
`

export const Postfix = styled.div`
  position: absolute;
  top: 0em;
  padding: 0.8em 1.3em;
  max-width: 140px;
  height: 46px;
  overflow: hidden;
  background: var(--form-element-border);
  color: var(--color-text);
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.label && `
    top: 2em;
  `}
 
  ${(props) => props.align === "right"?`
        right: 0;
        border-top-right-radius: var(--color-card-border-radius);
        border-bottom-right-radius: var(--color-card-border-radius);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    `:`
        left: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: var(--color-card-border-radius);
        border-bottom-left-radius: var(--color-card-border-radius);
    `}
  ${(props) => props.invalid && `
    border:1px solid #dc3545; 
    height: 45px;
    background: #dc3545; 
    color: #fff;
  `}
  
`

export const Strength = styled.div`
  width: 100%;
`
export const StrengthBar = styled.div`
  width: 100%;
  height: 5px;
  background: #e5e5e5;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid #ced4da;
  border-top: 0;
  direction: ltr;
  ${(props) => props.color && `
      border-color: ${props.color};
  `}
`
export const StrengthBarValue = styled.div`
  width: 0%;
  background: #ccc;
  
  ${(props) => props.value && `
      width: ${props.value};
    transition: width linear .5s;    
  `}
  ${(props) => props.color && `
      background: ${props.color};
  `}
  height: 5px;
  border-bottom-left-radius: 15px;
  ${(props) => props.value && props.value === "100%" && `
    border-bottom-right-radius: 15px;
  `}
`
export const StrengthMessage = styled.p`
  text-align: right;
  position: absolute;
  top: 0;
  left: 0.4em;
  font-size: .8em;
  margin: .3em 0 0 0;
  ${(props) => props.color && `
      color: ${props.color};
  `}
`
export const PasswordRules = styled.ul`
  margin: 0;
  padding: .5em 0 1em 0;
  list-style: none;
`
export const PasswordRule = styled.li`
  font-size: .9em;
  color: #cdcdcd;
  font-weight: 500;
  padding: .2em 0;
  & svg{
    font-size: .5em
  }
  
  ${(props) => props.check && `
       & svg{
        visibility: visible;
        font-size: .9em
      }
      color: #21a326;
      transition: all ease-in-out .3s;
  `}
  
  
  
`