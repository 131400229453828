import styled from "styled-components";
import {Button} from "react-bootstrap";
export const Container = styled.div`
  display: flex;
  position: relative;
  ${(props) => props.margin && `
    margin: 1em 0;
  `};
  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
  }
`;
export const PickerButton = styled(Button)`
  width: 100%;
  padding: ${(props) => !props.padding?`0.8em 1.3em;`:`${props.padding};`}
  border-radius: var(--form-element-border-radius);
  background: var(--form-element-background);
  color: var(--form-element-text-color);
  border-color: var(--form-element-border);
  &:hover{
    background: var(--color-secondary);
    color: var(--color-secondary-on2);
    border-color: var(--color-secondary);
  }
  &:focus{
    box-shadow: none;
  }
  &:active, &:focus{
    border:1px solid  var(--color-secondary) !important;
    color: var(--form-element-text-color) !important;
    background: var(--form-element-background) !important;

  }
  &:disabled{
    background: var(--form-element-background-disabled) !important;
    color: var(--form-element-text-color-disabled) !important;
  }
`
export const CalendarContainer = styled.div`
  position: absolute;
  top: 42px;
  left: 0;
  z-index: 999;
  & .react-calendar__navigation{
    background: var(--color-popup-background);
    color: var(--color-popup-text);
  }
  & abbr[title]{
    text-decoration: none !important;
  }
  & abbr[data-bs-original-title]{
    text-decoration: none !important;
  }
  & .react-calendar__tile{
    border: 1px solid var(--color-popup-border) !important;
  }
  & .react-calendar{
    background: var(--color-popup-background);
    border: 1px solid var(--color-popup-border);
    font-size: 14px;
  }
  & .react-calendar button{
    color: var(--color-popup-text);
  }
  & .react-calendar button:hover{
    background-color: var(--color-secondary);
    color: var(--color-secondary-on2);
  }
  & .react-calendar__month-view__days__day--weekend{
    color: var(--color-secondary) !important;
  }
  & .react-calendar__month-view__days__day--weekend:hover{
    color: var(--color-secondary-on2) !important;
  }
  & .react-calendar__tile:enabled:hover, 
  & .react-calendar__tile:enabled:focus{
    background-color: var(--color-secondary);
    color: var(--color-secondary-on2);
  }
  & .react-calendar__tile--now{
    color: var(--color-secondary-on2) !important;
    background-color: #8bc34a !important;
  }
`

