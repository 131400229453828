import styled from "styled-components";
import {Col, Form} from 'react-bootstrap';

export const LoginContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 5em auto;
`
export const RegisterContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 5em auto;
`
export const BlockText = styled(Form.Text)`
  font-size: 1em;
  color: #333;
  & a{
    display: inline;
    color: var(--color-secondary);
    margin: 0 .3em;
  }
`
export const FormTitle = styled.h5`
  margin-bottom: 1.5em;
  font-size: 1.2em;
  font-weight: 500;
  color: #444;
  text-align: center;
  line-height: 1.7em;
`
export const FormSubTitle = styled.h5`
  margin-bottom: 1.5em;
  font-size: 1em;
  font-weight: 500;
  color: #444;
  text-align: center;
  line-height: 1.7em;
  ${(props) => props.red && `
        color: #d50f0a;
  `}
  
`

export const EmailVerificationSuccessfully = styled.div`
  color: #4caf50;
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.7em;
  & svg{
    font-size: 4em;
    margin-bottom: 0.3em;
  }
`

export const ProfilePasswordHelp = styled.div`
  margin-top: 1.8em
`
export const Flex = styled.div`
  display: flex;
  direction: ltr;
  & .dropdown{
    width: 100%;
  }
  & .month-dropdown{
    margin: 0 .2em
  }
`