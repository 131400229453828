import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import Slider from "./Slider";
import {Container, Row, Col, NavLink} from 'react-bootstrap';
import DrawResults from "./DrawResults";
import Prizes from "./Prizes";
import Steps from "./Steps";
import SearchResults from "./SearchResults";
import BaseMaster from "../BaseMaster";
import {withAccountContext} from "../../contexts/AccountContext";
import withAuth from "../../contexts/withAuth";
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <Slider/>
                <DrawResults />
                <Prizes />
                <Steps />
                <SearchResults />
            </React.Fragment>

        );
    }
}

export default withApplicationContext(withAccountContext(Home));