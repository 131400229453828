import React, {Component} from 'react';
import {BallContainer} from "./style.jsx";
import {Container, Row, Col, NavLink} from 'react-bootstrap';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
class Ball extends Component {

    render() {
        const {borderColor, applicationContext, reserve, number} = this.props;
        return (
            <BallContainer reserve={reserve} borderColor={borderColor}>
                {reserve && <div className={"reserve-num"}>{applicationContext.translator("Reserve Number")}</div>}
                {number}
            </BallContainer>
        );
    }
}
Ball.propTypes = {
    number: PropTypes.string,
    borderColor: PropTypes.string,
}
export default withApplicationContext(Ball);