import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {Container, Form} from 'react-bootstrap';
import SubHeaderBar from "../../components/ui/SubHeaderBar/SubHeaderBar";
import {withAlert} from "react-alert";
import * as Yup from "yup";
import {Formik} from "formik";
import InputBox from "../../components/ui/InputBox";
import {LoginContainer, FormTitle, FormSubTitle} from "./style.jsx";
import Button from "../../components/ui/Button";
import withRouter from "../../contexts/withRouter";
import MobileEnterCode from "./MobileEnterCode";
import {msisdn} from "../../helpers";
import Registration from "./Registration";
import EnterMobileForm from "./Forms/EnterMobileForm";
import BaseMaster from "../BaseMaster";

class MobileVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: "",
            submitting: false,
            successfully: false,
        }

    }


    componentDidMount() {

    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, alert} = this.props;
        this.setState({ submitting: true });
        setTimeout(() => {
            //alert.error(applicationContext.translator("Your login credentials don't match."));
            this.setState({ submitting: false, successfully: true });
        }, 3000)
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <Registration>
                    {this.state.successfully?(
                        <MobileEnterCode />
                    ):(
                        <EnterMobileForm
                            onSubmit={this.handleSubmit}
                            submitting={this.state.submitting}
                        />
                    )}
                </Registration>
            </React.Fragment>



        );
    }
}

export default withAlert()(withRouter(withApplicationContext(MobileVerification)));