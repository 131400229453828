import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import withAuth from "../../../contexts/withAuth";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AccountService from "../../../services/AccountService";
import AdminService from "../../../services/AdminService";
import {accountId, msisdn, numberWithCommas, renderAccountID, ticketId, toDate} from "../../../helpers";
import DataList from "../../../components/ui/DataList/DataList";
import HorizontalBalls from "../../../components/ui/Ball/HorizontalBalls";
import {Ball, BallContainer} from "../../../components/ui/Ticket/style";
import Search from "./Search";
import {Status} from "../../MyAccount/DepositsHistory/style";
import DetailsTable from "../../../components/ui/DetailsTable/DetailsTable";
import {PopupText} from "../../../components/ui/AppHeader/style";
import Button from "../../../components/ui/Button";
import InputBox from "../../../components/ui/InputBox/InputBox";

class DepositsHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            search: {

            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
            dataStatus: props.status,
            description: null,
            submitting: false
        }

    }
    componentDidMount = async () => {
        await this.fetchData(1);

        this.setState({ loading: false })
    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        if(!search)
            search = {
                status: this.state.dataStatus
            };

        let data = await AdminService.deposits({
            page,
            limit: 10,
            sort_field,
            sort_dir,
            ...(search && search)
        });
        this.setState({
            loadingData: false,
            data: data,
            sort:{
                key: sort_field,
                dir: sort_dir
            },
            search
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} status={this.state.dataStatus} doFetchData={this.fetchData}/>;
    }
    onUpdateSearch = (data) => {
        this.setState({
            search: data
        })
    }
    renderStatus = (value, index) => {
        const {applicationContext} = this.props;
        return <Status status={value.toLowerCase()}>{applicationContext.translator(value)}</Status>;
    }
    renderTronScanLink = (value) => {
        if(value.startsWith("Request")) return value;
        let v = value;
        let first = v.substring(0, (value.length / 2) + 10);
        let last = v.substring((value.length / 2) + 10);
        return (
            <a href={"https://tronscan.org/#/transaction/" + value} target={"_blank"}>{first}<br/>{last}</a>
        )
    }
    onView = (row) => {
        const {applicationContext} = this.props;
        let data = [
            {title: "Account ID", value: renderAccountID(row.user_id)},
            {title: "Balance", value: numberWithCommas(row['balance_before']) + ".00 USD"},
            {title: "Amount", value: numberWithCommas(row.amount) + ".00 USD"},
            {title: "New Balance", value: numberWithCommas(row['balance_new']) + ".00 USD"},
            {title: "Hash code", value: this.renderTronScanLink(row.receipt_hash_code)},
            {title: "Status", value: this.renderStatus(row.status.label)}
        ];
        if(row['description'])
            data.push({title: "Description", value: row.description});
        return applicationContext.onOpenPopup("Deposit Information", <DetailsTable data={data} />);
    }
    onApprove = async (row, callback) => {
        const {applicationContext, alert} = this.props;
        console.log(this.state.search)
        this.setState({ description: null });
        let component = (
            <React.Fragment>
                <PopupText>{applicationContext.translator("Are you sure to approve this transaction?")}</PopupText>
                <InputBox as="textarea"
                          name={"description"}
                          onChange={(e) => this.setState({ description: e.target.value })}
                          margin={"0"}
                          rows={4}
                          value={this.state.description}
                          placeholder={applicationContext.translator("Description")}
                />
            </React.Fragment>
        );
        let footer = (
            <React.Fragment>
                <Button color={"success"} submitting={this.state.submitting} onClick={(e) => this.handleApprove(e, row, callback, 1)} margin={"0 .2em"}>{applicationContext.translator("Yes, I'm sure")}</Button>
                <Button color={"danger"} disabled={this.state.submitting} onClick={(e) => applicationContext.onCloseConfirmPopup(e, callback)} margin={"0 .2em"}>{applicationContext.translator("No")}</Button>
            </React.Fragment>

        );
        applicationContext.onOpenConfirmPopup("Approval", component, footer, callback)
    }
    handleApprove = async (e, row, callback, status) => {
        const {applicationContext, adminContext, alert} = this.props;
        applicationContext.onUpdatePopupSubmitting(true);

        await AdminService.updateDeposit(row.id, {
            status,
            description: this.state.description
        }).then((data) => {
            applicationContext.onCloseConfirmPopup(e, () => {
                this.setState({
                    submitting: false,
                    description: null
                }, () => {
                    callback(data.updated, this.state.search.status !== ""?"delete":"update")
                    adminContext.doUpdateCounters(data['counter']);
                })
            })
        }).catch((e) => {
            Object.keys(e.response.data.errors).map((field) => {
                alert.error(applicationContext.translator(e.response.data.errors[field][0]))
            })
        })
    }
    onDecline = async (row, callback) => {
        const {applicationContext, alert} = this.props;
        this.setState({ description: null });
        let component = (
            <React.Fragment>
                <PopupText>{applicationContext.translator("Are you sure to decline this transaction?")}</PopupText>
                <InputBox as="textarea"
                          name={"description"}
                          onChange={(e) => this.setState({ description: e.target.value })}
                          margin={"0"}
                          rows={4}
                          value={this.state.description}
                          placeholder={applicationContext.translator("Description")}
                />
            </React.Fragment>
        );
        let footer = (
            <React.Fragment>
                <Button color={"success"} submitting={this.state.submitting} onClick={(e) => this.handleApprove(e, row, callback, 2)} margin={"0 .2em"}>{applicationContext.translator("Yes, I'm sure")}</Button>
                <Button color={"danger"} disabled={this.state.submitting} onClick={(e) => applicationContext.onCloseConfirmPopup(e, callback)} margin={"0 .2em"}>{applicationContext.translator("No")}</Button>
            </React.Fragment>

        );
        applicationContext.onOpenConfirmPopup("Approval", component, footer, callback)
    }

    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number"},
                        {key: "user_id", label: "Account ID", render: renderAccountID, dir: "ltr"},
                        {key: "balance_before", label: "Balance", format: "amount", dir: "ltr"},
                        {key: "amount", label: "Amount", format: "amount", dir: "ltr", sortable: true},
                        {key: "balance_new", label: "New Balance", format: "amount", dir: "ltr"},
                        {key: "status.label", label: "Status", render: this.renderStatus, sortable: true, sortKey: 'status'},
                        {key: "created_at", label: "Date", format: "date"},
                        {key: "created_at", label: "Time", format: "time"},
                        {key: "", label: "Actions", actions: [
                                {label: "View", route: "#", onClick: this.onView, submitting: true},
                                {label: "Approve", route: "#", onClick: this.onApprove, color: "#4caf50", hidden: ["status.code", "IN", [1]]},
                                {label: "Decline", route: "#", onClick: this.onDecline, color: "#f44336", hidden: ["status.code", "IN", [2]]},
                            ]}
                    ]}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(DepositsHistory)))));