import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import MyAccount from "../MyAccount";
import {Status} from "./style"
import DataList from "../../../components/ui/DataList";
import {accountId, convertToDate, convertToTime} from "../../../helpers";
import withAuth from "../../../contexts/withAuth";
import AccountService from "../../../services/AccountService";
import BaseMaster from "../../BaseMaster";
import {withAccountContext} from "../../../contexts/AccountContext";
class DepositsHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            sort: {
                key: 'id',
                dir: 'desc'
            },
            search: {

            },
            loading: true,
            loadingData: true
        }

    }


    componentDidMount = async () => {
        await this.fetchData(1);
        this.setState({
            loading: false
        })
    }
    renderStatus = (value, index) => {
        const {applicationContext} = this.props;
        return <Status status={value.toLowerCase()}>{applicationContext.translator(value)}</Status>;
    }
    fetchData = async (page, sort_field = 'id', sort_dir = 'desc') => {
        this.setState({ loadingData: true })
        let data = await AccountService.deposits({
            page,
            limit: 10,
            sort_field,
            sort_dir
        });
        this.setState({
            loadingData: false,
            data: data,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        })
    }
    renderSearch = () => {
        return null;
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <MyAccount loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number", sortable: true},
                        {key: "user_id", label: "Account ID", render: accountId, dir: "ltr"},
                        {key: "created_at", label: "Date", format: "date", sortable: true},
                        {key: "created_at", label: "Time", format: "time"},
                        {key: "balance_before", label: "Balance", format: "amount", dir: "ltr"},
                        {key: "amount", label: "Amount", format: "amount", dir: "ltr", sortable: true},
                        {key: "balance_new", label: "New Balance", format: "amount", dir: "ltr"},
                        {key: "status.label", label: "Status", render: this.renderStatus, sortable: true, sortKey: 'status'}
                    ]}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                />
            </MyAccount>
        );
    }
}

export default withAlert()(withApplicationContext(withAccountContext(withAuth(DepositsHistory))));