import api from "./adminApi";
import {queryStringSerialize} from "../helpers";
import axios from "axios";
import AdminTokenService from "./AdminTokenService";

const getUserBoard = () => {
    return api.get("/account/profile/me").then((response) => {
        AdminTokenService.setUser(response.data);
        return AdminTokenService.getUser();
    });
};

const draws = (data) => {
    return api.get("/admin/draws/list?"+ queryStringSerialize(data)).then((response) => {
        return response.data;
    });
};

const draw = (data) => {
    return api.put("/admin/draws", data).then((response) => {
        return response.data;
    });
};

const checkMasterPassword = (data) => {
    return api.get("/admin/check_master_password?" + queryStringSerialize(data)).then((response) => {
        return response.data;
    });
};

const tickets = (data) => {
    return api
        .get("/account/tickets?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const users = (data) => {
    return api
        .get("/admin/users/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const deactivate = (id) => {
    return api
        .get("/admin/users/deactivate/"+ id)
        .then((response) => {
            return response.data;
        });
};

const activate = (id) => {
    return api
        .get("/admin/users/activate/"+ id)
        .then((response) => {
            return response.data;
        });
};

const deposits = (data) => {
    return api
        .get("/account/deposits?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const updateDeposit = (id, data) => {
    return api
        .post(`/admin/deposit/update/${id}`, data)
        .then((response) => {
            return response.data;
        });
};

const counters = () => {
    return api
        .get(`/admin/reports/counters`)
        .then((response) => {
            return response.data;
        });
};

const overview = () => {
    return api
        .get(`/admin/reports/overview`)
        .then((response) => {
            return response.data;
        });
};

const totalOverview = (data) => {
    return api
        .get(`/admin/reports/overview/total?`+queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const totalDeposits = (data) => {
    return api
        .get(`/admin/reports/deposits/total?`+queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const ranking = (data) => {
    return api
        .get(`/admin/reports/ranking?`+queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const transfer = (data) => {
    return api
        .post(`/admin/users/transfer`, data)
        .then((response) => {
            return response.data;
        });
};

const AdminService = {
    getUserBoard,
    draws,
    draw,
    tickets,
    users,
    activate,
    deactivate,
    deposits,
    updateDeposit,
    counters,
    overview,
    totalOverview,
    totalDeposits,
    ranking,
    checkMasterPassword,
    transfer
};

export default AdminService;