import React, {Component} from 'react';
import {withApplicationContext} from "../../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import MyAccount from "../../MyAccount";
import EmailCheckInbox from "../../../Register/EmailCheckInbox";
import {CenterContainer} from "./style";
import EmailForm from "../../../Register/Forms/EmailForm";
import withAuth from "../../../../contexts/withAuth";
import BaseMaster from "../../../BaseMaster";
import {withAccountContext} from "../../../../contexts/AccountContext";

class UpdateEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            submitting: false,
            successfully: false
        }

    }


    componentDidMount() {

    }
    handleSubmit = async (values, actions) => {
        const {applicationContext, alert} = this.props;
        this.setState({ submitting: true });
        setTimeout(() => {
            //alert.error(applicationContext.translator("Your login credentials don't match."));
            this.setState({ submitting: false, successfully: true });
        }, 3000)
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <MyAccount>
                <CenterContainer>
                    {this.state.successfully?(
                        <EmailCheckInbox message={applicationContext.translator("The email verification link sent to your new email address successfully.")}/>
                    ):(
                        <EmailForm
                            onSubmit={this.handleSubmit}
                            submitting={this.state.submitting}
                            submitLabel={applicationContext.translator("Update")}
                        />
                    )}
                </CenterContainer>
            </MyAccount>
        );
    }
}

export default withAlert()(withApplicationContext(withAccountContext(withAuth(UpdateEmail))));