import React, {Component} from 'react';
import {
    FooterMenuContainer,
    FooterMenu,
    SocialLink,
    FooterCopyrightContainer,
    ConditionText,
    CopyrightText
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
class Footer extends Component {

    render() {
        const {applicationContext} = this.props;

        return (
            <React.Fragment>
                {/*<FooterMenuContainer>*/}
                {/*    <Container>*/}
                {/*        <FooterMenu>*/}
                {/*            <FooterMenuItem><NavLink to={"/responsible-gaming"}>{applicationContext.translator("Responsible Gaming")}</NavLink></FooterMenuItem>*/}
                {/*            <FooterMenuItem><NavLink to={"/terms-and-conditions"}>{applicationContext.translator("Terms and conditions")}</NavLink></FooterMenuItem>*/}
                {/*            <FooterMenuItem><NavLink to={"/self-exclusion"}>{applicationContext.translator("Self Exclusion")}</NavLink></FooterMenuItem>*/}
                {/*            <FooterMenuItem><NavLink to={"/dispute-resolution"}>{applicationContext.translator("Dispute Resolution")}</NavLink></FooterMenuItem>*/}
                {/*            <FooterMenuItem><NavLink to={"/anti-money-laundering"}>{applicationContext.translator("Anti-money laundering")}</NavLink></FooterMenuItem>*/}

                {/*        </FooterMenu>*/}
                {/*        <FooterMenu>*/}
                {/*            <FooterMenuItem><NavLink to={"#"}>{applicationContext.translator("Fairness & RNG Testing Methods")}</NavLink></FooterMenuItem>*/}
                {/*            <FooterMenuItem><NavLink to={"/kyc-policies"}>{applicationContext.translator("KYC Policies, Privacy & Management of Personal Data")}</NavLink></FooterMenuItem>*/}
                {/*            <FooterMenuItem><NavLink to={"/accounts-payouts-bonuses"}>{applicationContext.translator("Accounts, Payouts & Bonuses")}</NavLink></FooterMenuItem>*/}

                {/*        </FooterMenu>*/}

                {/*    </Container>*/}
                {/*</FooterMenuContainer>*/}
                <FooterCopyrightContainer>
                    <Container>
                        <img src={"/assets/images/balls.png"} width={120} style={{ marginBottom: "1em" }}/>
                        <ConditionText>
                            <span>{applicationContext.translator("If you have problem you can check the FAQ page")}</span><br/>
                            <span>{applicationContext.translator("If didn't solve your problem you can contact to support team by following email")}</span><br/>
                            <span><a href="mailto:support@6-46.com">support@6-46.com</a></span>
                            <span><SocialLink href={"https://instagram.com/lottery646/"} target={"_blank"}><FontAwesomeIcon icon={faInstagram} /></SocialLink></span>
                        </ConditionText>
                        <CopyrightText>
                            6-46, a trademark and product of PMC GROUP, 115B, Old Mint Street, Valletta, VLT1515, MALTA © 2023, All rights reserved.<br/>
                            All games are live broadcasts from Portomaso St. Julian's, Malta. Portomaso Casino is regulated by Malta Gaming Authority License No. MGA/CL1/447/2008
                        </CopyrightText>
                    </Container>
                </FooterCopyrightContainer>

            </React.Fragment>
        );
    }
}
Footer.propTypes = {

}
export default withApplicationContext(Footer);