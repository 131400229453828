import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {
    SectionContainer,
    CenterCol,
    StepDescription,
    StepsTitle,
    StepsList, StepItem, StepNumber,
    StepSubDescription
} from "./style.jsx";
import {Container, Row, Col, NavLink} from 'react-bootstrap';
import CheckMyTicket from "../../components/ui/CheckMyTicket";
import CheckNumber from "../../components/ui/CheckNumbers";

class Steps extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer>
                <Container>
                    <Row>
                        <CenterCol md={5}>
                            <CheckMyTicket />
                        </CenterCol>
                        <CenterCol md={5}>
                            <CheckNumber />
                        </CenterCol>
                        <CenterCol md={2}>
                            <img src={"/assets/images/trust-wallet.png"} />
                        </CenterCol>
                    </Row>
                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(Steps);