import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {withAccountContext} from "../../contexts/AccountContext";
import SubHeaderBar from "../../components/ui/SubHeaderBar/SubHeaderBar";
import Loading from "../../components/ui/Loading";
import SearchService from "../../services/SearchService";
import {Container} from "react-bootstrap";
import withRouter from "../../contexts/withRouter";
import {Content, NotFound, Title, TitleValue} from "./style";
class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: null,
            notFound: false
        }


    }
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if(prevProps.params['slug'] !== this.props.params['slug']){
            await this.fetchData();
        }
    }


    componentDidMount = async () => {
        await this.fetchData();

    }

    fetchData = async () => {
        const {applicationContext, params} = this.props;
        this.setState({ loading: true })
        SearchService.page(applicationContext.state.locale, params['slug']).then((data) => {
            this.setState({
                page: data
            })
        }).catch((e) => {
            this.setState({ notFound: true })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <SubHeaderBar />
                {this.state.loading?(
                    <Loading transparent={true} animation={true} />
                ):(
                    <Container>
                        {this.state.notFound?(<NotFound><img src={"/assets/images/404.png"}/></NotFound>):(
                            <React.Fragment>
                                <Title dir={applicationContext.translator("direction")}><TitleValue>{this.state.page.title}</TitleValue></Title>
                                <Content dir={applicationContext.translator("direction")} dangerouslySetInnerHTML={{__html: this.state.page.content}} />
                            </React.Fragment>
                        )}
                    </Container>
                )}

            </React.Fragment>

        );
    }
}

export default withApplicationContext(withRouter(withAccountContext(Page)));