import styled from "styled-components";
export const Content = styled.div`
  ${(props) => props.dir && `direction: ${props.dir}  !important;`};
    & *{
      font-size: 16px !important;
      text-align: justify !important;
      font-weight: 500 !important;
      line-height: 1.7em !important;
    }
  & strong{
    font-weight: 800 !important;
    font-size: 1.2em !important;
  }
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    & li strong{
      font-size: 1em !important;
    }
  }
`;
export const NotFound = styled.div`
  text-align: center;
`
export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const TitleValue = styled.div`
  ${(props) => props.dir && `direction: ${props.dir} !important;`};
  width: fit-content;
  padding: 1em 2em;
  background: var(--color-primary);
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 2em;
  border-radius: 15px;
`