import styled from "styled-components";
export const SearchBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3em 0;
`;
export const SearchItem = styled.div`
  min-width: 150px;
`