import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {InputCol, InputError, InputGroup, Label, Wrapper} from "./style.jsx";
import {Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputBox from "../InputBox";
import { Datepicker } from '@ijavad805/react-datepicker';
import moment from "jalali-moment";

class PersianDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    onChange = (e) => {
        const {onChange} = this.props;
        if(onChange)
            return onChange(e);
    }
    onFocus = (e) => {
        const {onFocus, focus, applicationContext} = this.props;
        if(focus)
            e.target.select()

        if(onFocus)
            return onFocus(e);
    }
    onBlur = (e) => {
        const {onBlur} = this.props;

        if(onBlur)
            return onBlur(e);
    }
    value = () => {
        return this.refInput.value;
    }
    select = () => {
        this.refInput.select();
    }
    render() {
        const {applicationContext, value, name, label, line, margin, floatLabel, invalid, touched, errors} = this.props;
        return (
            <React.Fragment>
                <InputGroup as={Row} margin={margin} floatLabel={floatLabel}>
                    <InputCol>
                        <Wrapper line={line} invalid={touched && touched[name] && (errors[name] !== undefined)}>
                            {label && <Label floatLabel={floatLabel}>{label} {line?":":""}</Label>}
                            <Datepicker
                                {...this.props}
                                format={'YYYY/MM/DD'}
                                lang={applicationContext.state.locale}
                                theme={'blue'}
                                closeWhenSelectADay={true}
                                onOpen={() => {
                                    if(document.getElementsByClassName("__datepicker-input").length > 0){
                                        let inputs = document.getElementsByClassName("__datepicker-input");
                                        for (let input of inputs) {
                                            let tag = input.getElementsByTagName("input");
                                            if(tag.length > 0){
                                                let inputBox = tag[0];
                                                inputBox.readOnly = true;
                                            }

                                        }

                                    }

                                }}
                                input={<InputBox />}
                                adjustPosition={'auto'}
                            />
                        </Wrapper>
                        {touched && touched[name] && <InputError hidden={errors[name] === undefined && true}>{errors[name]}</InputError>}
                    </InputCol>
                </InputGroup>

            </React.Fragment>

        );
    }
}
PersianDatePicker.propTypes = {
    type: PropTypes.string,
    margin: PropTypes.string,
    invalid: PropTypes.bool,
    padding: PropTypes.string,
    fullWidth: PropTypes.bool,
    size: PropTypes.number,
    onlyNumber: PropTypes.bool,
    center: PropTypes.bool,
    fontSize: PropTypes.string,
    line: PropTypes.bool,
    floatLabel: PropTypes.bool
}
export default withApplicationContext(PersianDatePicker);