import styled from "styled-components";
import {Col, Form} from "react-bootstrap"

export const InputContainer = styled(Form.Control)`
  
  
  
  
`;
export const InputCol = styled(Col)`
  position: relative;
`
export const Wrapper = styled.div`
  position: relative;
  ${(props) => props.line && `
        display: flex;
        justify-content: space-between;
        align-items: center;      
        & .form-label{
            min-width: 100px;
            margin-bottom: 0;
        }
  `}
  ${(props) => props.invalid && `
        & .form-control {
            border-color: #dc3545;
            padding-right: calc(1.5em + 0.75rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);    
        }
  `}
  
  & .__datepicker-pick-day-container .__datepicker-pick-day .__datepicker-days{
    font-size: 1.3em;
    width: 35px;
    height: 35px;
    margin: 1px 2px;
    border-radius: 100%;
  }
  & .__datepicker-pick-day-container .__datepicker-pick-day .__datepicker-days:hover:not(.__datepicker-day-disabled):not(.__datepicker-today):not(.__datepicker-selected){
    background: var(--color-primary); 
  }
  & .__datepicker-pick-day-container .__datepicker-pick-day .__datepicker-days.__datepicker-today{
    border-color: var(--color-primary);
    color: #333;
  }
  & .__datepicker.__datepicker-theme-blue * {
    --primary: var(--color-primary);
    --secondary:var(--color-secondary);
  }
  & .__datepicker *{
    color: #333 !important;  
  }
  & .__datepicker-dropdown-body .__datepicker-dropdown-body-header{
    font-size: 1.2em;
  }
  
`
export const Label = styled(Form.Label)`
  font-weight: 500;
  color: #333;
  ${(props) => props.floatLabel && `
          position: absolute;
          top: -0.8em;
          right: 1em;
          background: #fff;
          padding: 0.2em 0em;
          text-align: right;
          border-radius: 5px;
          margin-bottom: 0;
          color: #686868;
  `}

`;
export const InputGroup = styled(Form.Group)`
  margin-bottom: 1em;
  ${(props) => props.margin && `
        margin: ${props.margin} !important;
  `}
  ${(props) => props.floatLabel && `
        margin-bottom: 1.5em !important;
  `}
  
`

export const InputError = styled(Form.Text)`
    color: #dc3545;
    margin: 0em 0.3em;
    ${(props) => props.hidden && `
        display: none;
    `}

`

export const DatepickerContainer = styled.div`
  &.__datepicker{
    width: 100%;
  }
    
`