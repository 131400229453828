import React, {Component} from 'react';
import {JackpotTitle, JackpotPriceText, JackpotContainer} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
class JackpotPrice extends Component {

    render() {
        const {applicationContext, size, margin} = this.props;
        return (
            <JackpotContainer margin={margin}>
                <JackpotTitle size={size}>{applicationContext.translator("Jackpot Price")}</JackpotTitle>
                <JackpotPriceText size={size}>{applicationContext.translator("1,000,000,000 Toman")}</JackpotPriceText>
            </JackpotContainer>
        );
    }
}
JackpotPrice.propTypes = {
    size: PropTypes.number,
    margin: PropTypes.bool,
}
export default withApplicationContext(JackpotPrice);