import React, {Component} from 'react';
import {withApplicationContext} from "../../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../../contexts/withRouter";
import {withAdminContext} from "../../../../contexts/AdminContext";
import {OverviewContainer} from "./style";
import LoggedIn from "../../LoggedIn";
import withAdminAuth from "../../../../contexts/withAdminAuth";
import AdminService from "../../../../services/AdminService";
import {accountId, convertToDate, renderAccountID, ticketId} from "../../../../helpers";
import DataList from "../../../../components/ui/DataList/DataList";
import Search from "./Search";
import {NavLink} from "react-router-dom";
import {ROUTE_ADMIN_PLAYERS} from "../../../../routes/routes";

class TicketsBuyingRanking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            search: {
                type: "total",
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                sort: "tickets",
                align: "desc",
                limit: 15,
                start_date: "",
                end_date: ""
            },
            sort: {
                key: 'id',
                dir: 'desc'
            }
        }

    }
    componentDidMount = async () => {
        await this.fetchData(1);
        this.setState({ loading: false })
    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        let data = await AdminService.ranking(search??this.state.search);
        this.setState({
            loadingData: false,
            data: data,
            search: search??this.state.search,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} doFetchData={this.fetchData}/>;
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number"},
                        {key: "user_id", label: "Account ID", render: renderAccountID},
                        {key: 'tickets', label: "Total Buy", format: "numberCommas"}
                    ]}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                    footer={true}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(TicketsBuyingRanking)))));