import api from "./api";
import {queryStringSerialize} from "../helpers";

const searchByTicketId = (id) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api.get("/search/tickets/id/" + id.replaceAll(" ", ""), config).then((response) => {
        return response.data;
    });
};

const drawsResults = (data) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api.get("/search/draws/results?"+queryStringSerialize(data), config).then((response) => {
        return response.data;
    });
};

const page = (locale, slug) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return api.get(`/pages/show/${locale}/${slug}`, config).then((response) => {
        return response.data;
    });
};


const SearchService = {
    searchByTicketId,
    drawsResults,
    page
};

export default SearchService;