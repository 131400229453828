import React from "react";

const TicketCreatingContext = React.createContext({});
const TicketCreatingProvider = TicketCreatingContext.Provider;
const TicketCreatingConsumer = TicketCreatingContext.Consumer;
const withTicketCreatingContext = Component => React.forwardRef((props, ref) => (
    <TicketCreatingConsumer>
        {(response) => {
            return <Component {...props} ticketCreatingContext={response} ref={ref} />;
        }}
    </TicketCreatingConsumer>
));
export { TicketCreatingProvider, TicketCreatingConsumer, withTicketCreatingContext };
