import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {EmailVerificationSuccessfully} from "./style.jsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class EmailVerification extends Component {
    render() {
        const {applicationContext, message} = this.props;
        return (
            <EmailVerificationSuccessfully>
                <FontAwesomeIcon icon={"check-circle"} /><br/>
                {message?message:applicationContext.translator("Your Email has been subscribed successfully.")}
                <br/>
                {applicationContext.translator("An email has been sent to your inbox")}
            </EmailVerificationSuccessfully>


        );
    }
}
export default (withApplicationContext(EmailVerification));