import React from 'react';
import {ROUTE_ADMIN_LOGIN, ROUTE_LOGIN} from "../routes/routes";
import StorageService from "../services/StorageService";
import {useLocation, Navigate} from 'react-router-dom';

const withAuth = WrappedComponent =>  (props) => {
    let loggedIn = props.accountContext.state.loggedIn;
    if(!loggedIn) {
        console.log(props.navigate)
        let location = props.location??useLocation();
        StorageService.set('redirect', location.pathname);
        return <Navigate replace to={ROUTE_LOGIN} />;

    }


    return (
        <WrappedComponent
            {...props}

        />
    );
};

export default (withAuth);