import React, {Component} from 'react';
import {Route, Routes} from "react-router-dom";
import {withApplicationContext} from "../contexts/ApplicationContext"
import {
    ROUTE_BUY_TICKET,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_REGISTER,
    ROUTE_REGISTER_COMPLETION, ROUTE_REGISTER_DRAW_RESULTS,
    ROUTE_REGISTER_MOBILE
} from "./routes";
import Login from "../scenes/Login";
import Home from "../scenes/Home/Home";
import BuyTicket from "../scenes/BuyTicket/BuyTicket";
import Register from "../scenes/Register/EmailVerification";
import MobileVerification from "../scenes/Register/MobileVerification";
import RegisterCompletion from "../scenes/Register/RegisterCompletion";
import BaseMaster from "../scenes/BaseMaster";
import DrawResults from "../scenes/DrawResults";
import Page from "../scenes/Page";

class PublicRouter extends Component {
    render() {
        return (
            <Routes>
                <Route  path={ROUTE_HOME} element={<BaseMaster><Home /></BaseMaster>} />
                <Route  path={ROUTE_BUY_TICKET} element={<BaseMaster><BuyTicket /></BaseMaster>} />
                <Route  path={ROUTE_LOGIN} element={<BaseMaster><Login /></BaseMaster>} />
                <Route  path={ROUTE_REGISTER} element={<BaseMaster><Register /></BaseMaster>} />
                <Route  path={ROUTE_REGISTER_MOBILE} element={<BaseMaster><MobileVerification /></BaseMaster>} />
                <Route  path={ROUTE_REGISTER_COMPLETION} element={<BaseMaster><RegisterCompletion /></BaseMaster>} />
                <Route  path={ROUTE_REGISTER_DRAW_RESULTS} element={<BaseMaster><DrawResults /></BaseMaster>} />
                <Route exact={true} path={"/:slug"} element={<BaseMaster><Page /></BaseMaster>} />
            </Routes>
        );
    }
}

export default withApplicationContext((PublicRouter));