import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  color: #4caf50;
  font-size: 1.1em;
  font-weight: 500;
  line-height: 1.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg{
    font-size: 4em;
    margin-bottom: 0.3em;
  }
`;
export const Wrapper = styled.div`
  ${(props) => props.color && `
    & svg, & .message{
        color: ${props.color} !important;
    }
  `}
`
export const Text = styled.p`
  margin: 0;
`
export const SubText = styled.p`
  font-size: .8em;
  color: #333;
  
`