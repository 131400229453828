import styled from "styled-components";

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
`
export const SearchButton = styled.div`
  width: fit-content;
  display: flex;
  padding: 0 1em;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 700px) {
    padding: .3em 1em;
    flex: 1 0 100%;
  }
`
export const SearchItem = styled.div`
  width: 100%;
  padding: 0 .2em;
  @media screen and (max-width: 700px) {
    padding: .3em .2em;
    flex: 1 0 100%;
  }
`