import styled from "styled-components";

export const JackpotContainer = styled.h5`
  ${(props) => props.margin && `
    margin: 1em 0;
  `};
`
export const JackpotTitle = styled.h5`
  font-size: 1.5em;
  margin: 0;
  font-weight: bold;
  ${(props) => props.size && props.size === 1 && `
    font-size: 1em;
    @media screen and (max-width: 700px) {
      text-align: center;
      font-size: 1em;
    }
  `};
`;
export const JackpotPriceText = styled.h1`
  font-size: 2.5em;
  font-weight: 600;
  margin: 0;
  ${(props) => props.size && props.size === 1 && `
    font-size: 2.2em;
    @media screen and (max-width: 700px) {
      text-align: center;
      font-size: 1.8em;
      margin-bottom: 2em;
    }
  `};
`
