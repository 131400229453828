import React, {Component} from 'react';
import {
    Container,
    Title,
    InputBox,
    InputBoxWrapper,
    Label,
    Input,
    Button,
    Prize
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import moment from "jalali-moment";
import {convertToEnglishNumbers, getNextFriday, isNumber, sortNumbers, utcTime} from "../../../helpers";
import * as Yup from "yup";
import {Form, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import {InputError} from "../CheckMyTicket/style";
import CommonService from "../../../services/CommonService";

class CheckNumber extends Component {
    timer1 = null;
    timer2 = null;
    timer3 = null;
    timer4 = null;
    timer5 = null;
    timer6 = null;
    constructor(props) {
        super(props);
        this.state = {
            number1: "",
            number2: "",
            number3: "",
            number4: "",
            number5: "",
            number6: "",
            lastLottery: props.applicationContext.state.lastLottery,
            submitting: false,
            won_number: []
        }
    }
    componentDidMount() {
        this.setState({
            lastLottery: this.props.applicationContext.state.lastLottery
        })
    }

    onKeyPress = (e) => {
        if(!isNumber(e))
            e.preventDefault();

    }
    handleSearch = (values, actions) => {
        const {applicationContext} = this.props;
        this.setState({ submitting: true, won_number: [], reserve: null, prize: null })
        setTimeout(async () => {
            let common = await CommonService.fetchCommonData();
            let lastLottery = common['last_lottery'];
            applicationContext.updateLastLottery(lastLottery, () => {
                let numbers = lastLottery['won_number'];

                let reserve = lastLottery['reserve_number'].toString();
                let prize = 0;
                let num = [(values.number1), (values.number2), (values.number3), (values.number4), (values.number5), (values.number6)];
                num = sortNumbers(num);

                num.forEach((n) => {
                    if(numbers.includes(n.toString()))
                        prize++;
                })
                if(prize === 6) prize = 100;
                if(prize === 5 && num.includes(reserve.toString()))
                    prize++;

                let message = applicationContext.translator("You did not win any prizes");
                if(prize === 100)
                    message = applicationContext.translator("You won 1,000,000,000 Toman");
                else if(prize === 3)
                    message = applicationContext.translator("You won 10 free ticket");
                else if(prize === 4)
                    message = applicationContext.translator("You won 20 free ticket");
                else if(prize === 5)
                    message = applicationContext.translator("You won 50,000,000 Toman");
                else if(prize === 6)
                    message = applicationContext.translator("You won 500,000,000 Toman");
                this.setState({
                    won_number: numbers,
                    reserve: (prize === 6)?reserve:null,
                    prize: message,
                    number1: values.number1,
                    number2: values.number2,
                    number3: values.number3,
                    number4: values.number4,
                    number5: values.number5,
                    number6: values.number6,
                    submitting: false
                })
            })

        }, 2000)
    }
    render() {
        const {date, applicationContext} = this.props;
        return (
            <Container>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={{
                        number1: this.state.number1,
                        number2: this.state.number2,
                        number3: this.state.number3,
                        number4: this.state.number4,
                        number5: this.state.number5,
                        number6: this.state.number6
                    }}
                    validationSchema={Yup.object().shape({
                        number1: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("The ticket number must be 10 digits"), (value) => value > 0 && value <= 46)
                            .label(applicationContext.translator("Number 1")),
                        number2: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("The ticket number must be 10 digits"), (value) => value > 0 && value <= 46)
                            .label(applicationContext.translator("Number 2")),
                        number3: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("The ticket number must be 10 digits"), (value) => value > 0 && value <= 46)
                            .label(applicationContext.translator("Number 3")),
                        number4: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("The ticket number must be 10 digits"), (value) => value > 0 && value <= 46)
                            .label(applicationContext.translator("Number 4")),
                        number5: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("The ticket number must be 10 digits"), (value) => value > 0 && value <= 46)
                            .label(applicationContext.translator("Number 5")),
                        number6: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("The ticket number must be 10 digits"), (value) => value > 0 && value <= 46)
                            .label(applicationContext.translator("Number 6")),
                        number: Yup.string()
                            .test('empty-validation', applicationContext.translator("You must enter 6 numbers"), (value, context) => {
                                this.setState({
                                    prize: null,
                                    won_number: [],
                                    reserve: null
                                })
                                return (context.parent.number1 && context.parent.number2 && context.parent.number3 && context.parent.number4 && context.parent.number5 && context.parent.number6)
                            })
                            .test('value-duplicate', applicationContext.translator("Your entered numbers can't be duplicate"), (value, context) => {
                                this.setState({
                                    prize: null,
                                    won_number: [],
                                    reserve: null
                                })
                                let numbers = [];
                                if(numbers.includes(context.parent.number1))
                                    return false;
                                numbers.push(context.parent.number1);
                                if(numbers.includes(context.parent.number2))
                                    return false;
                                numbers.push(context.parent.number2);
                                if(numbers.includes(context.parent.number3))
                                    return false;
                                numbers.push(context.parent.number3);
                                if(numbers.includes(context.parent.number4))
                                    return false;
                                numbers.push(context.parent.number4);
                                if(numbers.includes(context.parent.number5))
                                    return false;
                                numbers.push(context.parent.number5);
                                if(numbers.includes(context.parent.number6))
                                    return false;
                                numbers.push(context.parent.number6);
                                return true
                            })
                            .test('value', applicationContext.translator("Your entered numbers must be digit between 1 to 46"), (value, context) => {
                                return (
                                    (context.parent.number1 > 0 && context.parent.number1 <= 46) &&
                                    (context.parent.number2 > 0 && context.parent.number2 <= 46) &&
                                    (context.parent.number3 > 0 && context.parent.number3 <= 46) &&
                                    (context.parent.number4 > 0 && context.parent.number4 <= 46) &&
                                    (context.parent.number5 > 0 && context.parent.number5 <= 46) &&
                                    (context.parent.number6 > 0 && context.parent.number6 <= 46)
                                )
                            })
                            .label(applicationContext.translator("Numbers"))
                    })}
                    onSubmit={this.handleSearch}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                <Title>
                                    {applicationContext.translator("Check my numbers")}{" "}{applicationContext.translator("For the last draw")} <br/>
                                    <span>{this.state.lastLottery?(moment(utcTime(this.state.lastLottery.date), 'YYYY/MM/DD').locale("fa").format('dddd, D MMMM YYYY')):null}</span>
                                </Title>
                                <InputBoxWrapper>
                                    <InputBox>
                                        <Label>{applicationContext.translator("Your 6 numbers")}</Label>
                                        <Input name={"number1"}
                                               type={"text"}
                                               first={true}
                                               maxLength={2}
                                               tabIndex={1}
                                               onChange={(e) => {
                                                   e.target.value = convertToEnglishNumbers(e.target.value);
                                                   handleChange(e);
                                               }}
                                               won={this.state.won_number.includes(this.state.number1)}
                                               reserve={this.state.reserve === this.state.number1}
                                               onKeyPress={this.onKeyPress}
                                               isInvalid={touched && touched.number1 && errors.number1}
                                               value={values.number1}
                                        />
                                    </InputBox>
                                    <Input name={"number2"} type={"text"}
                                           second={true}
                                           maxLength={2}
                                           tabIndex={2}
                                           onChange={(e) => {
                                               e.target.value = convertToEnglishNumbers(e.target.value);
                                               handleChange(e);
                                           }}
                                           won={this.state.won_number.includes(this.state.number2)}
                                           reserve={this.state.reserve === this.state.number2}
                                           onKeyPress={this.onKeyPress}
                                           isInvalid={touched && touched.number2 && errors.number2}
                                           value={values.number2}
                                    />
                                    <Input name={"number3"} type={"text"}
                                           maxLength={2}
                                           tabIndex={3}
                                           onChange={(e) => {
                                               e.target.value = convertToEnglishNumbers(e.target.value);
                                               handleChange(e);
                                           }}
                                           won={this.state.won_number.includes(this.state.number3)}
                                           reserve={this.state.reserve === this.state.number3}
                                           onKeyPress={this.onKeyPress}
                                           isInvalid={touched && touched.number3 && errors.number3}
                                           value={values.number3}
                                    />
                                    <Input  name={"number4"} type={"text"}
                                           maxLength={2}
                                            tabIndex={4}
                                            onChange={(e) => {
                                                e.target.value = convertToEnglishNumbers(e.target.value);
                                                handleChange(e);
                                            }}
                                            won={this.state.won_number.includes(this.state.number4)}
                                            reserve={this.state.reserve === this.state.number4}
                                            onKeyPress={this.onKeyPress}
                                            isInvalid={touched && touched.number4 && errors.number4}
                                           value={values.number4}
                                    />
                                    <Input name={"number5"} type={"text"}
                                           maxLength={2}
                                           tabIndex={5}
                                           onChange={(e) => {
                                               e.target.value = convertToEnglishNumbers(e.target.value);
                                               handleChange(e);
                                           }}
                                           won={this.state.won_number.includes(this.state.number5)}
                                           reserve={this.state.reserve === this.state.number5}
                                           onKeyPress={this.onKeyPress}
                                           isInvalid={touched && touched.number5 && errors.number5}
                                           value={values.number5}
                                    />
                                    <Input  name={"number6"} type={"text"}
                                            maxLength={2}
                                            tabIndex={6}
                                            onChange={(e) => {
                                                e.target.value = convertToEnglishNumbers(e.target.value);
                                                handleChange(e);
                                            }}
                                            won={this.state.won_number.includes(this.state.number6)}
                                            reserve={this.state.reserve === this.state.number6}
                                            isInvalid={touched && touched.number6 && errors.number6}
                                            onKeyPress={this.onKeyPress}
                                           value={values.number6}
                                    />
                                    <InputError hidden={errors.number === undefined && true}>{errors.number}</InputError>
                                    {this.state.prize && <Prize>{this.state.prize}</Prize>}
                                </InputBoxWrapper>
                                <Button type={"submit"} tabIndex={6} disabled={this.state.submitting}>
                                    {this.state.submitting?<Spinner animation="border" variant="light" size={"sm"} />:applicationContext.translator("Search")}
                                </Button>

                            </Form>

                        )}
                </Formik>

            </Container>
        );
    }
}
CheckNumber.propTypes = {
    date: PropTypes.any
}
export default withApplicationContext(CheckNumber);