import React, {Component} from 'react';
import {HeaderContainer, Items, Item} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class SubHeaderBar extends Component {

    render() {
        const {name, margin, applicationContext} = this.props;

        return (
            <HeaderContainer>
                {/*<Container>*/}
                {/*    <Items>*/}
                {/*        <Item>{applicationContext.translator("Jackpot always at 1,000,000,000 Toman")}</Item>*/}
                {/*        <Item>{applicationContext.translator("Participate for free in the national id lottery get a chance to win 100,000,000 toman weekly")}</Item>*/}
                {/*        <Item>{applicationContext.translator("Drawing, Every friday night at 21:00")}</Item>*/}
                {/*    </Items>*/}
                {/*</Container>*/}
            </HeaderContainer>
        );
    }
}
SubHeaderBar.propTypes = {

}
export default withApplicationContext(SubHeaderBar);