import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {Col, Container, Form, Row} from 'react-bootstrap';
import SubHeaderBar from "../../components/ui/SubHeaderBar/SubHeaderBar";
import {withAlert} from "react-alert";
import {BlockText, LoginContainer} from "./style.jsx";
import {ROUTE_ACCOUNT, ROUTE_REGISTER} from "../../routes/routes";
import withRouter from "../../contexts/withRouter";
import StorageService from "../../services/StorageService";
import LoginForm from "./Forms/LoginForm";
import {NavLink} from "react-router-dom";
import BaseMaster from "../BaseMaster";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false
        }

    }


    onLoggedIn = () => {
        const {navigate} = this.props;
        let redirect = (StorageService.get('redirect'))??ROUTE_ACCOUNT;
        StorageService.remove('redirect');
        navigate(redirect)
    }


    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <SubHeaderBar />
                <Container>
                    <LoginContainer>
                        <LoginForm onCallback={this.onLoggedIn}/>
                        <br/>
                        <Form.Group as={Row} className={"mb-3"}>
                            <Col className={"text-center"}>
                                <BlockText>
                                    {applicationContext.translator("Don't have an account yet?")} <NavLink to={ROUTE_REGISTER}>{applicationContext.translator("Click here to register")}</NavLink>
                                </BlockText>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={"mb-5"}>
                            <Col className={"text-center"}>
                                <BlockText>
                                    {applicationContext.translator("Forgot your Password?")} <NavLink to={"#"}>{applicationContext.translator("Click here to reset")}</NavLink>
                                </BlockText>
                            </Col>
                        </Form.Group>
                    </LoginContainer>
                </Container>
            </React.Fragment>


        );
    }
}

export default withAlert()(withApplicationContext(withRouter(Login)));