import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {Container, Row, Col, Form, Spinner} from 'react-bootstrap';
import SubHeaderBar from "../../components/ui/SubHeaderBar/SubHeaderBar";
import {withAlert} from "react-alert";
import * as Yup from "yup";
import {Formik} from "formik";
import InputBox from "../../components/ui/InputBox";
import {LoginContainer, BlockText, FormTitle, EmailVerificationSuccessfully} from "./style.jsx";
import Button from "../../components/ui/Button";
import {ROUTE_LOGIN, ROUTE_REGISTER_COMPLETION} from "../../routes/routes";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import withRouter from "../../contexts/withRouter";
import InputNumberBox from "../../components/ui/InputNumberBox";
import EnterMobileCodeForm from "./Forms/EnterMobileCodeForm";

class MobileVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            submitting: false,
            successfully: false,
        }

    }


    componentDidMount() {

    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, alert, navigate} = this.props;
        this.setState({ submitting: true });
        setTimeout(() => {
            //alert.error(applicationContext.translator("Your login credentials don't match."));
            //this.setState({ submitting: false, successfully: true });
            navigate(ROUTE_REGISTER_COMPLETION)
        }, 3000)
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <EnterMobileCodeForm
                onSubmit={this.handleSubmit}
                submitting={this.state.submitting}
            />
        );
    }
}

export default withAlert()(withRouter(withApplicationContext(MobileVerification)));