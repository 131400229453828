import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import {Container} from "./style";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import Card from "../../../components/ui/Card";
import AdminService from "../../../services/AdminService";
import {accountId, msisdn, numberWithCommas, toDate} from "../../../helpers";
import * as Yup from "yup";
import {Form} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import DataList from "../../../components/ui/DataList/DataList";
import DetailsTable from "../../../components/ui/DetailsTable/DetailsTable";
Yup.addMethod(Yup.string, 'requiredIf', function(list, message) {
    return this.test('requiredIf', message, function(value) {
        const { path, createError } = this;
        var anyHasValue = list.some(value => {
            return Boolean(document.querySelector(`input[name="${value}"]`).value);

        });
        return !value && !anyHasValue
            ? createError({ path, message })
            : true;
    });
});
class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingData: false,
            searchDone: false,
            form: {
                user_id: "",
                email: "",
                msisdn: "",
                national_id: ""
            },
            data: [],
            search: {

            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }

    }
    componentDidMount = async () => {

    }

    onSearch = async (values, actions) => {
        this.setState({
            searchDone: true
        }, async () => {
            await this.fetchData(1, this.state.sort.key, this.state.sort.dir, values)
        })

    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        let data = await AdminService.users({
            page,
            limit: 10,
            role: 'user',
            sort_field,
            sort_dir,
            ...(search??this.state.search)
        });
        this.setState({
            loadingData: false,
            data: data,
            search: search??this.state.search,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        }, callback)
    }
    onViewUser = (row) => {
        const {applicationContext} = this.props;
        let data = [
            {title: "Account ID", value: accountId(row.id)},
            {title: "E-Mail Address", value: row.email},
            {title: "First name", value: row.first_name},
            {title: "Last name", value: row.last_name},
            {title: "National id", value: row.national_id},
            {title: "Mobile number", value: msisdn(row.msisdn)},
            {title: "National id", value: row.national_id},
            {title: "Date of birth", value: toDate(row.date_of_birth, applicationContext.state.locale)},
            {title: "State", value: row.state_id?row.state_id.name:"-"},
            {title: "Balance", value: numberWithCommas(row.balance) + ".00 USD"},
            {title: "Registered Time", value: toDate(row.created_at, applicationContext.state.locale, true)}
        ];
        if(row['blacklisted_at'])
            data.push({title: "Blacklisted Time", value: toDate(row['blacklisted_at'], applicationContext.state.locale, true)});
        return applicationContext.onOpenPopup("User Information", <DetailsTable data={data} />);
    }
    onActivateUser = async (row, callback) => {
        const {applicationContext, alert} = this.props;
        await AdminService.activate(row.id).then((data) => {
            callback(data)
        }).catch((e) => {
            Object.keys(e.response.data.errors).map((field) => {
                alert.error(applicationContext.translator(e.response.data.errors[field][0]))
            })
        })
    }
    onDeActivateUser = async (row, callback) => {
        const {applicationContext, alert} = this.props;
        await AdminService.deactivate(row.id).then((data) => {
            callback(data)
        }).catch((e) => {
            Object.keys(e.response.data.errors).map((field) => {
                alert.error(applicationContext.translator(e.response.data.errors[field][0]))
            })
        })
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>

                {!this.state.loading &&
                    <React.Fragment>
                        <Container>
                            <Formik
                                innerRef={(ref) => this.refForm = ref}
                                initialValues={this.state.form}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email().requiredIf(['user_id','msisdn','national_id'], applicationContext.translator("At least one field is required.")),
                                    user_id: Yup.string().requiredIf(['email','msisdn','national_id'], applicationContext.translator("At least one field is required.")),
                                    msisdn: Yup.string().requiredIf(['user_id','email','national_id'], applicationContext.translator("At least one field is required.")),
                                    national_id: Yup.string().requiredIf(['user_id','msisdn','email'], applicationContext.translator("At least one field is required."))
                                })}
                                onSubmit={this.onSearch}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleSubmit,
                                      isSubmitting,
                                      setFieldValue
                                  }) =>
                                    (
                                        <Card maxWidth={800} center={true} top={"2em"}>
                                            <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                                <InputBox type="text"
                                                          name={"user_id"}
                                                          onChange={handleChange}
                                                          placeholder={applicationContext.translator("Account ID")}
                                                          value={values.user_id}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                                <InputBox type="text"
                                                          name={"email"}
                                                          onChange={handleChange}
                                                          placeholder={applicationContext.translator("E-Mail Address")}
                                                          value={values.email}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                                <InputBox type="text"
                                                          name={"msisdn"}
                                                          onChange={handleChange}
                                                          placeholder={applicationContext.translator("Mobile number")}
                                                          value={values.msisdn}
                                                          invalid={errors.searchGroup?.msisdn}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                                <InputBox type="text"
                                                          name={"national_id"}
                                                          onChange={handleChange}
                                                          placeholder={applicationContext.translator("National id")}
                                                          value={values.national_id}
                                                          invalid={errors.searchGroup?.national_id}
                                                          errors={errors}
                                                          touched={touched}
                                                />

                                                <Form.Group className={"text-center"}>
                                                    <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} className={"btn btn-account"} type={"submit"}>
                                                        {applicationContext.translator("Search")}
                                                    </Button>
                                                </Form.Group>


                                            </Form>
                                        </Card>
                                    )}
                            </Formik>


                        </Container>
                        {this.state.searchDone && <DataList
                            columns={[
                                {key: "id", label: "#", format: "number"},
                                {key: "id", label: "Account ID", render: accountId, dir: "ltr"},
                                {key: 'full_name', label: "Full name"},
                                {key: 'email', label: "E-Mail Address"},
                                {key: 'balance', label: "Balance", format: "amount", dir: "ltr"},
                                {key: "created_at", label: "Date", format: "date"},
                                {key: "", label: "Actions", actions: [
                                    {label: "View", route: "#", onClick: this.onViewUser, submitting: true},
                                    {label: "Activate", route: "#", onClick: this.onActivateUser, color: "#4caf50", hidden: ["blacklisted_at", "=", null]},
                                    {label: "Deactivate", route: "#", onClick: this.onDeActivateUser, color: "#f44336", hidden: ["blacklisted_at", "<>", null]},
                                ]}
                            ]}
                            data={this.state.data}
                            action={this.fetchData}
                            sort={this.state.sort}
                            loading={this.state.loadingData}
                        />}
                    </React.Fragment>
                }

            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(Search)))));