import styled from "styled-components";
import {Button as RButton, Form, FormControl} from "react-bootstrap"
export const Container = styled.div`
  border: 1px solid var(--color-secondary);
  border-radius: 13px;
  padding: 2em;
  width: 100%;
  min-height: 255px;
  @media screen and (max-width: 700px) {
    margin-top: 1em;
  }
`;
export const Title = styled.h5`
  color: var(--color-secondary);
  line-height: 1.3em;
  font-weight: 700;
  font-size: 1.6em;
  margin-bottom: 1.5em;
  
`;
export const InputBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5em 0 0.5em 0;
  justify-content: space-between;
  position: relative;
`
export const InputBox = styled.div`
  display: flex;
  align-items: center;
  background: var(--color-primary);
  color: var(--color-secondary-on2);
  border-radius: 20px;
  height: 40px;
  width: fit-content;
`;
export const Label = styled.span`
  
  width: 130px;
  min-width: 130px;
  padding: 0.4em 1em;
  
`;
export const Input = styled(FormControl)`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  border: 2px solid var(--color-primary);
  text-align: center;
  direction: ltr;
  font-size: 1em;
  padding: 0;
  font-weight: 500;
  ${(props) => (props.first)?`margin: 0 0.6em 0 0;`:``}
  background-image: none !important;
  
  &:focus{
    border-color: rgb(241 204 1);
    box-shadow: 0 0 0 0.25rem rgb(241 204 1 / 25%);
  }
  @media screen and (max-width: 700px) {
    width: 30px;
    height: 30px;
  }
  ${(props) => props.won && `
        background-color: #4caf50;
        color: #fff;
        border: 0;
        transition: all ease-in-out .2s;
  `}
  ${(props) => props.reserve && `
        background-color: var(--color-ball-reserve-background);
        color: var(--color-ball-reserve-text);
        border: 0;
        transition: all ease-in-out .2s;
  `}
  
`;
export const Button = styled(RButton)`
  width: calc(100% - 130px);
  margin-right: 130px;
  margin-bottom: 0.5em;
  margin-top: .5em;
  border:2px solid  var(--color-primary);
  color: var(--color-secondary-on2);
  background: #fff;
  height: 40px;
  border-radius: 20px;
  &:hover, &:focus{
    border:2px solid  var(--color-primary);
    color: var(--color-secondary-on2);
    background: var(--color-primary);
  }
  &:focus{
    box-shadow: 0 0 0 0.25rem rgb(241 204 1 / 25%) !important;
  }
  &:active {
    border:2px solid  var(--color-secondary) !important;
    color: #fff !important;;
    background: var(--color-secondary) !important;;
  }

  &:disabled{
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    opacity: var(--bs-btn-disabled-opacity);
  }
  
`


export const InputError = styled(Form.Text)`
    color: #dc3545;
    margin: 0em 0.3em;
      position: absolute;
      top: -23px;
      right: 7px;
    ${(props) => props.hidden && `
        display: none;
    `}
    

`
export const Prize = styled.div`
  position: absolute;
  top: -28px;
  right: 2px;
  color: #4caf50;
  font-size: 1em;
  font-weight: 600;
  width: 100%;
  text-align: center
`