import React, {Component} from 'react';
import {Route, Routes} from "react-router-dom";
import {withApplicationContext} from "../contexts/ApplicationContext"
import {
    ROUTE_ADMIN_DEPOSITS_RANKING_REPORTS,
    ROUTE_ADMIN_DEPOSITS_REPORTS, ROUTE_ADMIN_DRAW_HISTORY,
    ROUTE_ADMIN_ENTER_WINNING_NUMBER,
    ROUTE_ADMIN_LOGIN, ROUTE_ADMIN_MONTHLY_YEARLY_OVERVIEW,
    ROUTE_ADMIN_OVERVIEW,
    ROUTE_ADMIN_PENDING_DEPOSITS_HISTORY,
    ROUTE_ADMIN_PLAYERS, ROUTE_ADMIN_PLAYERS_CHARGE,
    ROUTE_ADMIN_PLAYERS_TICKETS, ROUTE_ADMIN_SEARCH, ROUTE_ADMIN_SEND_EMAILS, ROUTE_ADMIN_TICKET_BUYING_RANKING_REPORTS
} from "./routes";
import Login from "../scenes/Admin/Login";
import BaseMaster from "../scenes/Admin/BaseMaster";
import Overview from "../scenes/Admin/Overview";
import EnterWinningNumber from "../scenes/Admin/EnterWinningNumber";
import PlayerTickets from "../scenes/Admin/PlayerTickets";
import Users from "../scenes/Admin/Users";
import DepositsHistory from "../scenes/Admin/DepositsHistory";
import OverviewReport from "../scenes/Admin/Reports/Overview";
import Deposits from "../scenes/Admin/Reports/Deposits";
import DepositsRanking from "../scenes/Admin/Reports/DepositsRanking";
import TicketsBuyingRanking from "../scenes/Admin/Reports/TicketsBuyingRanking";
import DrawsHistory from "../scenes/Admin/DrawsHistory";
import Search from "../scenes/Admin/Search";
import ChargePlayer from "../scenes/Admin/ChargePlayer";
import SendEmail from "../scenes/Admin/SendEmail";

class AdminRouter extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Routes>
                <Route path={ROUTE_ADMIN_LOGIN} element={<BaseMaster><Login /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_OVERVIEW} element={<BaseMaster><Overview /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_MONTHLY_YEARLY_OVERVIEW} element={<BaseMaster><OverviewReport /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_ENTER_WINNING_NUMBER} element={<BaseMaster><EnterWinningNumber /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_PLAYERS_TICKETS} element={<BaseMaster><PlayerTickets /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_PLAYERS} element={<BaseMaster><Users /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_PENDING_DEPOSITS_HISTORY} element={<BaseMaster><DepositsHistory status={"0"} /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_DEPOSITS_REPORTS} element={<BaseMaster><Deposits /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_DEPOSITS_RANKING_REPORTS} element={<BaseMaster><DepositsRanking /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_TICKET_BUYING_RANKING_REPORTS} element={<BaseMaster><TicketsBuyingRanking /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_DRAW_HISTORY} element={<BaseMaster><DrawsHistory /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_SEARCH} element={<BaseMaster><Search /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_PLAYERS_CHARGE} element={<BaseMaster><ChargePlayer /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_SEND_EMAILS} element={<BaseMaster><SendEmail /></BaseMaster>} />


            </Routes>
        );
    }
}

export default withApplicationContext((AdminRouter));