import React, {Component} from 'react';
import {withApplicationContext} from "../contexts/ApplicationContext"
import PublicRouter from "./PublicRouter";
import AccountRouter from "./AccountRouter";

class FrontendRouters extends Component {
    render() {
        return (
            <React.Fragment>
                <PublicRouter />
                <AccountRouter />
            </React.Fragment>
        );
    }
}

export default withApplicationContext((FrontendRouters));