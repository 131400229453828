import React, {Component} from 'react';
import {
    HorizontalBallContainer,
    Plus
} from "./style.jsx";
import {Container, Row, Col, NavLink} from 'react-bootstrap';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import Ball from "./Ball";
import {convertStringToArray} from "../../../helpers";
import PropTypes from "prop-types";
class HorizontalBalls extends Component {

    render() {
        const {applicationContext, numbers, borderColor, reserve} = this.props;
        return (
            <HorizontalBallContainer>
                {numbers && numbers.map((number, index) => {
                    return (<Ball number={number} borderColor={borderColor}/>)
                })}
                {reserve &&
                <React.Fragment>
                    <Plus>+</Plus>
                    <Ball number={reserve} reserve={true} borderColor={borderColor}/>
                </React.Fragment>
                }
            </HorizontalBallContainer>
        );
    }
}
HorizontalBalls.propTypes = {
    numbers: PropTypes.array,
    reserve: PropTypes.number,
    borderColor: PropTypes.string,
}
export default withApplicationContext(HorizontalBalls);