import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import EnterMasterPassword from "../EnterMasterPassword";
import * as Yup from "yup";
import Card from "../../../components/ui/Card";
import {Form} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import AdminService from "../../../services/AdminService";

class ChargePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loggedIn: false,
            password: "",
            form: {
                account_id: "",
                amount: 0
            }
        }

    }
    componentDidMount = async () => {

    }

    onMasterCallback = (password) => {
        this.setState({
            loggedIn: true,
            password
        })
    }
    onSubmit = async (values, actions) => {
        const {applicationContext, alert, onCallback} = this.props;

        this.setState({ submitting: true });
        await AdminService.transfer({
            ...values,
            password: this.state.password
        }).then((data) => {
            this.setState({
                amount: "",
                account_id: ""
            })
            actions.resetForm();
            alert.success(applicationContext.translator("The user account has been charged successfully."));

        }).catch((e) => {
            if(e.response.data.hasOwnProperty('errors') && Object.keys(e.response.data.errors).length > 0){
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
                })
            }else{
                alert.error(applicationContext.translator(e.response.data.message));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        });
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                {this.state.loggedIn?(
                    <Formik
                        innerRef={(ref) => this.refForm = ref}
                        initialValues={this.state.form}
                        validationSchema={Yup.object().shape({
                            account_id: Yup.string().required("You must enter user ${path}").min(9).max(11).label("Account ID"),
                            amount: Yup.number()
                                .required(applicationContext.translator("You must enter your ${path}"))
                                .label(applicationContext.translator("Amount"))
                                .min(1, applicationContext.translator("Amount must be greater than or equal to 1")),
                        })}
                        onSubmit={this.onSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) =>
                            (
                                <Card maxWidth={800} center={true} top={"2em"}>
                                    <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                        <InputBox type={"text"}
                                                  name={"account_id"}
                                                  onChange={handleChange}
                                                  placeholder={applicationContext.translator("Account ID")}
                                                  value={values.account_id}
                                                  save={"off"}
                                                  errors={errors}
                                                  touched={touched}
                                        />
                                        <InputBox type="text"
                                                  name={"amount"}
                                                  onChange={handleChange}
                                                  value={values.amount}
                                                  save={"off"}
                                                  onlyNumber={true}
                                                  line={true}
                                                  maxLength={5}
                                                  errors={errors}
                                                  touched={touched}
                                                  postfix={"USD"}
                                                  postfixAlign={"right"}
                                        />
                                        <Form.Group className={"text-center"}>
                                            <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} type={"submit"}>
                                                {applicationContext.translator("Charge")}
                                            </Button>
                                        </Form.Group>


                                    </Form>
                                </Card>
                            )}
                    </Formik>
                ):(
                    <EnterMasterPassword onCallback={this.onMasterCallback}/>
                )}
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(ChargePlayer)))));