import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withApplicationContext} from "../../../../contexts/ApplicationContext";
import withRouter from "../../../../contexts/withRouter";
import {withAdminContext} from "../../../../contexts/AdminContext";
import withAdminAuth from "../../../../contexts/withAdminAuth";
import SearchTable from "../../../../components/ui/SearchTable";
import {yearToNowRange} from "../../../../helpers";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            years: []
        }
    }
    componentDidMount = async () => {
        const {applicationContext} = this.props;
        let years = [];
        let months = [];
        yearToNowRange(applicationContext.state.firstLottery, applicationContext.state.locale).map((year, index) => {
            years.push({ key: year, label: year})
        })
        months.push({ key: 1, label: "January"});
        months.push({ key: 2, label: "February"});
        months.push({ key: 3, label: "March"});
        months.push({ key: 4, label: "April"});
        months.push({ key: 5, label: "May"});
        months.push({ key: 6, label: "June"});
        months.push({ key: 7, label: "July"});
        months.push({ key: 8, label: "August"});
        months.push({ key: 9, label: "September"});
        months.push({ key: 10, label: "October"});
        months.push({ key: 11, label: "November"});
        months.push({ key: 12, label: "December"});
        this.setState({
            years,
            months,
            loading: false
        })
    }
    render() {
        const {doFetchData} = this.props;
        return (
            <SearchTable
                loading={this.state.loading}
                search={[
                    {id: 'type', type: 'dropdown', label: "Report period", data: [
                        { key: "total", label: "Total"},
                        { key: "range", label: "Range of date"},
                        { key: "monthly", label: "Monthly"},
                        { key: "yearly", label: "Yearly"}
                    ], defaultValue: this.props.data.type, disableEmpty: true},
                    {id: 'year', type: 'dropdown', label: "Year", data: this.state.years, hidden: ["type", "IN", ["total", "range"]], defaultValue: this.props.data.year, disableEmpty: true},
                    {id: 'month', type: 'dropdown', label: "Month", data: this.state.months, hidden: ["type", "IN", ["yearly", "total", "range"]], defaultValue: this.props.data.month, disableEmpty: true},
                    {id: 'start_date', type: 'datepicker', label: "Start Date", hidden: ["type", "IN", ["total", "monthly", "yearly"]]},
                    {id: 'end_date', type: 'datepicker', label: "End Date", hidden: ["type", "IN", ["total", "monthly", "yearly"]]},
                ]}
                sort={{
                    field: 'id',
                    dir: 'asc'
                }}
                doFetchData={doFetchData}
            />
        );
    }
}
Search.propTypes = {
    data: PropTypes.any,
    doFetchData: PropTypes.func
}
export default withApplicationContext(withRouter(withAdminContext(withAdminAuth(Search))));