import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter"
import EmailCheckInbox from "./EmailCheckInbox";
import Registration from "./Registration";
import EmailForm from "./Forms/EmailForm";
import AccountService from "../../services/AccountService";
import TokenService from "../../services/TokenService";
import StorageService from "../../services/StorageService";
import {ROUTE_ACCOUNT} from "../../routes/routes";
import {parseErrorMessage} from "../../helpers";
import AuthService from "../../services/AuthService";
import BaseMaster from "../BaseMaster";

class EmailVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            submitting: false,
            successfully: false
        }

    }


    componentDidMount() {

    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, alert} = this.props;
        this.setState({ submitting: true });
        AuthService.register(values.email, applicationContext.state.locale).then(async (token) => {
            if(token){
                this.setState({
                    successfully: true
                })
            }
        }).catch((e) => {
            if(e.response.data.hasOwnProperty('errors') && Object.keys(e.response.data.errors).length > 0){
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
                })
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(e)));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        })
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <Registration>
                    {this.state.successfully?(
                        <EmailCheckInbox />
                    ):(
                        <EmailForm
                            onSubmit={this.handleSubmit}
                            loginHint={true}
                            submitting={this.state.submitting}
                        />
                    )}
                </Registration>
            </React.Fragment>



        );
    }
}

export default withAlert()(withRouter(withApplicationContext(EmailVerification)));