import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import withAuth from "../../../contexts/withAuth";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AccountService from "../../../services/AccountService";
import AdminService from "../../../services/AdminService";
import {accountId, msisdn, numberWithCommas, renderAccountID, ticketId, toDate} from "../../../helpers";
import DataList from "../../../components/ui/DataList/DataList";
import HorizontalBalls from "../../../components/ui/Ball/HorizontalBalls";
import {Ball, BallContainer} from "../../../components/ui/Ticket/style";
import Search from "./Search";
import {Status} from "../../MyAccount/DepositsHistory/style";
import DetailsTable from "../../../components/ui/DetailsTable/DetailsTable";
import {PopupText} from "../../../components/ui/AppHeader/style";
import Button from "../../../components/ui/Button";
import InputBox from "../../../components/ui/InputBox/InputBox";

class DrawsHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            search: {
                status: "done"
            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
            dataStatus: props.status,
            description: null,
            submitting: false
        }

    }
    componentDidMount = async () => {
        await this.fetchData(1);

        this.setState({ loading: false })
    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {

        let data = await AdminService.draws({
            page,
            limit: 10,
            sort_field,
            sort_dir,
            ...(search??this.state.search)
        });
        this.setState({
            loadingData: false,
            data: data,
            search: search??this.state.search,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} status={this.state.dataStatus} doFetchData={this.fetchData}/>;
    }
    onUpdateSearch = (data) => {
        this.setState({
            search: data
        })
    }
    renderNumber = (value, index, row) => {
        return (
            value?(<BallContainer margin={"0"}>
                {value.map((number) => {
                    return (
                        <Ball editable={false}
                              fill={true}
                        >
                            <span>{number}</span>
                        </Ball>
                    )
                })}
                <Ball editable={false}
                      fill={true}
                      reserve={true}
                >
                    <span>{row['reserve_number']}</span>
                </Ball>
            </BallContainer>):("")
        );
    }

    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number"},
                        {key: "num_of_draw", label: "Round#"},
                        {key: "date", label: "Date", format: "date"},
                        {key: "won_number", label: "Numbers", render: this.renderNumber, dir: "ltr"},
                        {key: "tickets", label: "Total Tickets", format: "numberCommas"},
                        {key: 'won_2', label: "2 Matched", format: "numberCommas"},
                        {key: 'won_3', label: "3 Matched", format: "numberCommas"},
                        {key: 'won_4', label: "4 Matched", format: "numberCommas"},
                        {key: 'won_5', label: "5 Matched", format: "numberCommas"},
                        {key: 'won_6', label: "5+ Reserved", format: "numberCommas"},
                        {key: 'won_100', label: "6 Matched", format: "numberCommas"}
                    ]}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(DrawsHistory)))));