import React, {Component} from 'react';
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import {BlockText, FormTitle} from "../style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button/Button";
import {NavLink} from "react-router-dom";
import {ROUTE_ACCOUNT, ROUTE_REGISTER} from "../../../routes/routes";
import {Formik} from "formik";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import PropTypes from "prop-types";
import AuthService from "../../../services/AuthService";
import TokenService from "../../../services/TokenService";
import AccountService from "../../../services/AccountService";
import StorageService from "../../../services/StorageService";
import {parseErrorMessage} from "../../../helpers";
import {withAlert} from "react-alert";
import {withAccountContext} from "../../../contexts/AccountContext";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            submitting: false
        }
    }
    handleSubmit = async (values, actions) => {
        const {applicationContext, accountContext, alert, onCallback} = this.props;
        this.setState({ submitting: true });
        try {
            const token  = await AuthService.login(values.email, values.password);
            TokenService.updateLocalAccessToken(token);
            await AccountService.getUserBoard().then((data) => {
                accountContext.updateUserInfo(data, onCallback);
            });

        } catch (err) {
            this.setState({ submitting: false });
            alert.error(applicationContext.translator(parseErrorMessage(err)));
        }
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                initialValues={this.state}
                validationSchema={Yup.object().shape({
                    email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
                    password: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Password")).min(8, applicationContext.translator("Password must be minimum 8 characters"))
                })}
                onSubmit={this.handleSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) =>
                    (
                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                            <FormTitle>{applicationContext.translator("Enter username and password.")}</FormTitle>
                            <InputBox type="text"
                                      name={"email"}
                                      onChange={handleChange}
                                      placeholder={applicationContext.translator("E-Mail Address")}
                                      value={values.email}
                                      save={"off"}
                                      focus={true}
                                      errors={errors}
                                      touched={touched}
                            />
                            <InputBox type={this.state.showPassword?"text":"password"}
                                      name={"password"}
                                      onChange={handleChange}
                                      placeholder={applicationContext.translator("Password")}
                                      value={values.password}
                                      save={"off"}
                                      errors={errors}
                                      touched={touched}
                            />
                            <Form.Group className={"text-center"}>
                                <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} radius={10} className={"btn btn-account"} type={"submit"}>{applicationContext.translator("Log In")}</Button>
                            </Form.Group>
                        </Form>
                    )}
            </Formik>
        );
    }
}
LoginForm.propTypes = {
    onCallback: PropTypes.func.isRequired
}
export default withAlert()(withApplicationContext(withRouter(withAccountContext(LoginForm))));