import React, {Component} from 'react';
import {withApplicationContext} from "../../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import MyAccount from "../../MyAccount";
import {CenterContainer} from "./style";
import EnterMobileForm from "../../../Register/Forms/EnterMobileForm";
import EnterMobileCodeForm from "../../../Register/Forms/EnterMobileCodeForm";
import withAuth from "../../../../contexts/withAuth";
import BaseMaster from "../../../BaseMaster";
import {withAccountContext} from "../../../../contexts/AccountContext";

class UpdateMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: "",
            submitting: false,
            successfully: false,
        }

    }

    handleUpdateMobileSubmit = async (values, actions) => {
        const {applicationContext, alert} = this.props;
        this.setState({ submitting: true });
        setTimeout(() => {
            //alert.error(applicationContext.translator("Your login credentials don't match."));
            this.setState({ submitting: false, successfully: true });
        }, 3000)
    }

    handleCheckCodeSubmit = async (values, actions) => {
        const {applicationContext, alert} = this.props;
        this.setState({ submitting: true });
        setTimeout(() => {
            //alert.error(applicationContext.translator("Your login credentials don't match."));
            this.setState({ submitting: false, successfully: true });
        }, 3000)
    }
    componentDidMount() {

    }
    render() {
        const {applicationContext} = this.props;
        return (
            <MyAccount>
                <CenterContainer>
                    {this.state.successfully?(
                        <EnterMobileCodeForm
                            onSubmit={this.handleCheckCodeSubmit}
                            submitting={this.state.submitting}
                        />
                    ):(
                        <EnterMobileForm
                            onSubmit={this.handleUpdateMobileSubmit}
                            submitting={this.state.submitting}
                        />
                    )}
                </CenterContainer>
            </MyAccount>
        );
    }
}

export default withAlert()(withApplicationContext(withAccountContext(withAuth(UpdateMobile))));