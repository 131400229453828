import styled from "styled-components";

export const BallContainer = styled.div`
  width: 52px;
  height: 52px;
  min-width: 52px;
  border-radius: 100%;
  border: 2px solid ${(props) => (props.borderColor)??`var(--color-primary)`};
  margin: 0 0.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
  ${(props) => (props.reserve) && `
        background: var(--color-ball-reserve-background);
        border-color: var(--color-ball-reserve-background);
        color: var(--color-ball-reserve-text);
  `};
  position: relative;
  & .reserve-num{
    position: absolute;
    color: #333;
    top: -29px;
    right: 0;
    font-size: .66em;
    white-space: pre;
    text-align: center;
  }
  @media screen and (max-width: 700px) {
    width: 35px;
    height: 35px;
    min-width: 35px;
    font-size: 1.1em;
    & .reserve-num{
      top: -23px;
    }
  }
  
`;
export const HorizontalBallContainer = styled.div`
  display: flex;
  margin: 2em 0 1em 0;
  direction: ltr;
`;
export const Plus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 600;
`