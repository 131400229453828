
export const ROUTE_HOME = `/`;
export const ROUTE_BUY_TICKET = `/buy-ticket`;
export const ROUTE_LOGIN = `/login`;
export const ROUTE_REGISTER = `/register`;
export const ROUTE_REGISTER_MOBILE = `/register/mobile`;
export const ROUTE_REGISTER_COMPLETION = `/register/complete/:hash`;
export const ROUTE_REGISTER_DRAW_RESULTS = `/draws/results`;
export const ROUTE_ACCOUNT = `/account`;
export const ROUTE_ACCOUNT_PROFILE = `/account/profile`;
export const ROUTE_ACCOUNT_PROFILE_EMAIL_UPDATE = `/account/profile/update/email`;
export const ROUTE_ACCOUNT_PROFILE_MOBILE_UPDATE = `/account/profile/update/mobile`;
export const ROUTE_ACCOUNT_MY_TICKETS = `/account/tickets`;
export const ROUTE_ACCOUNT_PLAYED_HISTORY = `/account/history/played`;
export const ROUTE_ACCOUNT_DEPOSIT = `/account/deposit`;
export const ROUTE_ACCOUNT_DEPOSITS_HISTORY = `/account/history/deposits`;
export const ROUTE_FAQ = `/faq`;
export const ROUTE_GAME_MANUAL = `/game-manual`;
export const ROUTE_ABOUT = `/about-us`;


export const ROUTE_ADMIN_LOGIN = `/administrator/login`;
export const ROUTE_ADMIN_OVERVIEW = `/administrator/overview`;
export const ROUTE_ADMIN_MONTHLY_YEARLY_OVERVIEW = `/administrator/reports/overview`;
export const ROUTE_ADMIN_PLAYERS_TICKETS = `/administrator/tickets`;
export const ROUTE_ADMIN_PLAYERS = `/administrator/players`;
export const ROUTE_ADMIN_PLAYERS_CHARGE = `/administrator/players/charge`;
export const ROUTE_ADMIN_DRAW_HISTORY = `/administrator/history/draws`;
export const ROUTE_ADMIN_WINNERS_HISTORY = `/administrator/history/winners`;
export const ROUTE_ADMIN_DEPOSITS_REPORTS = `/administrator/reports/deposits`;
export const ROUTE_ADMIN_DEPOSITS_RANKING_REPORTS = `/administrator/ranking/deposits`;
export const ROUTE_ADMIN_TICKET_BUYING_RANKING_REPORTS = `/administrator/ranking/tickets`;
export const ROUTE_ADMIN_PENDING_DEPOSITS_HISTORY = `/administrator/history/deposits/pending`;
export const ROUTE_ADMIN_SEND_EMAILS = `/administrator/players/email`;
export const ROUTE_ADMIN_ENTER_WINNING_NUMBER = `/administrator/draws/enter`;
export const ROUTE_ADMIN_SEARCH = `/administrator/search`;

