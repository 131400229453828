import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {Container, Row, Col, Form, Spinner} from 'react-bootstrap';
import SubHeaderBar from "../../components/ui/SubHeaderBar/SubHeaderBar";
import {withAlert} from "react-alert";
import * as Yup from "yup";
import {Formik} from "formik";
import InputBox from "../../components/ui/InputBox";
import {RegisterContainer, BlockText, FormTitle, Flex, FormSubTitle, ProfilePasswordHelp} from "./style.jsx";
import Button from "../../components/ui/Button";
import {ROUTE_ACCOUNT, ROUTE_LOGIN} from "../../routes/routes";
import {NavLink} from "react-router-dom";
import withRouter from "../../contexts/withRouter"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EmailCheckInbox from "./EmailCheckInbox";
import Loading from "../../components/ui/Loading";
import {
    dayRange,
    isValidNationalCode,
    msisdn,
    parseErrorMessage,
    passwordCheck,
    toDataList,
    toSearchDataList, yearRange, zeroPad
} from "../../helpers";
import PersianDatePicker from "../../components/ui/PersianDatePicker";
import moment from "jalali-moment";
import Dropdown from "../../components/ui/Dropdown";
import SearchDropdown from "../../components/ui/SearchDropdown";
import AccountService from "../../services/AccountService";
import AuthService from "../../services/AuthService";
import TokenService from "../../services/TokenService";
import StorageService from "../../services/StorageService";
import BaseMaster from "../BaseMaster";
import {withAccountContext} from "../../contexts/AccountContext";
import {InputError, Label} from "../../components/ui/InputBox/style";
const floatLabel = false;
class RegisterCompletion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            msisdn: "",
            state_id: "",
            national_id: "",
            first_name: "",
            last_name: "",
            password: "",
            year_of_birth: "",
            month_of_birth: "",
            day_of_birth: "",
            password_confirmation: "",
            loading: true,
            passwordHelp: null,
            submitting: false,
            successfully: false,
            registerEndpoint: null
        }
    }


    componentDidMount = async () => {
        const {params} = this.props;
        let hash = JSON.parse(decodeURIComponent(atob(params.hash)));
        this.setState({
            email: hash.e,
            registerEndpoint: hash.u,
            loading: false
        })
    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, accountContext, navigate, alert} = this.props;
        let date_of_birth = values.year_of_birth+"-"+zeroPad(values.month_of_birth, 2)+"-"+zeroPad(values.day_of_birth, 2);
        if(applicationContext.state.locale === "fa"){
            date_of_birth = values.year_of_birth+"/"+zeroPad(values.month_of_birth, 2)+"/"+zeroPad(values.day_of_birth, 2);
            let date = moment.from(date_of_birth, 'fa', 'YYYY/MM/DD');
            date_of_birth = (date['_a'][0].toString()+"-"+zeroPad((date['_a'][1] + 1).toString(), 2)+"-"+zeroPad(date['_a'][2].toString(), 2))
        }
        this.setState({ submitting: true });
        AccountService.register(this.state.registerEndpoint, {
            ...values,
            date_of_birth
        }).then(async (token) => {
            if(token){
                TokenService.updateLocalAccessToken(token);
                const userInfo = await AccountService.getUserBoard();
                accountContext.updateUserInfo(userInfo, () => {
                    let redirect = (StorageService.get('redirect'))??ROUTE_ACCOUNT;
                    StorageService.remove('redirect');
                    navigate(redirect)
                });
            }
        }).catch((e) => {
            if(e.response.data.hasOwnProperty('errors') && Object.keys(e.response.data.errors).length > 0){
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
                })
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(e)));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        })
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <SubHeaderBar />
                <Container>
                    <RegisterContainer>
                        {this.state.loading?(<Loading transparent={true} animation={true} />):(<Formik
                            innerRef={(ref) => this.refForm = ref}
                            initialValues={this.state}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
                                national_id: Yup.string()
                                    .required(applicationContext.translator("You must enter your ${path}"))
                                    .label(applicationContext.translator("National id"))
                                    .test('len', applicationContext.translator("${path} must be exactly 10 characters"), val => val.toString().length === 10),
                                first_name: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("First name")),
                                last_name: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Last name")),
                                year_of_birth: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Year of birth")),
                                month_of_birth: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Year of birth")),
                                day_of_birth: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Year of birth")),
                                date_of_birth: Yup.string().test('birth_date', applicationContext.translator("You must select your date of birth"), (value, context) => {
                                    return (context.parent.year_of_birth && context.parent.month_of_birth && context.parent.day_of_birth);
                                }),
                                state_id: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("State")),
                                password: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                                    .test('password', applicationContext.translator("The password must contain at least 8 english characters and include upper and lower case letters, numbers and a symbol"), (value) => {
                                        let check = passwordCheck(value);
                                        return (check.message === "Strong")
                                    })
                                    .label(applicationContext.translator("Password")),
                                password_confirmation: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                                    .oneOf([Yup.ref('password'), null], applicationContext.translator('Passwords must match'))
                                    .label(applicationContext.translator("Password Confirm"))
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) =>
                                (
                                    <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                        <FormTitle>{applicationContext.translator("Please enter all information in the form for register completion.")}</FormTitle>
                                        <FormSubTitle red={true}>{applicationContext.translator("Note that your registration must be completed in order to be able to enter your account and participate in the lottery.")}</FormSubTitle>
                                        <Row>
                                            <Col md={6}>
                                                <InputBox type="text"
                                                          name={"email"}
                                                          onChange={handleChange}
                                                          value={values.email}
                                                          save={"off"}
                                                          floatLabel={floatLabel}
                                                          label={applicationContext.translator("E-Mail Address")}
                                                          disabled={true}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <InputBox type="text"
                                                          name={"msisdn"}
                                                          onChange={handleChange}
                                                          label={applicationContext.translator("Mobile number")}
                                                          value={values.msisdn}
                                                          save={"off"}
                                                          maxLength={10}
                                                          onlyNumber={true}
                                                          floatLabel={floatLabel}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <InputBox type="text"
                                                          name={"first_name"}
                                                          onChange={handleChange}
                                                          label={applicationContext.translator("First name")}
                                                          value={values.first_name}
                                                          save={"off"}
                                                          floatLabel={floatLabel}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <InputBox type="text"
                                                          name={"last_name"}
                                                          onChange={handleChange}
                                                          label={applicationContext.translator("Last name")}
                                                          value={values.last_name}
                                                          save={"off"}
                                                          floatLabel={floatLabel}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <InputBox type="text"
                                                          name={"national_id"}
                                                          onChange={handleChange}
                                                          label={applicationContext.translator("National id")}
                                                          value={values.national_id}
                                                          save={"off"}
                                                          floatLabel={floatLabel}
                                                          onlyNumber={true}
                                                          maxLength={10}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <SearchDropdown
                                                    name={"state_id"}
                                                    onChange={(e) => e.selectedKey[0] !== undefined && setFieldValue('state_id', e.selectedKey[0]) }
                                                    data={toSearchDataList(applicationContext.translator, applicationContext.state.states, ['name'])}
                                                    label={applicationContext.translator("State")}
                                                    value={values.state_id}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Label>{applicationContext.translator("Date of birth")}</Label>
                                                <Flex>
                                                    <Dropdown
                                                        name={"year_of_birth"}
                                                        onChange={handleChange}
                                                        value={values.year_of_birth}
                                                        invalid={touched.year_of_birth && errors.year_of_birth}
                                                        noInvalidIcon={true}
                                                        boxClassName={"dropdown year-dropdown"}
                                                        padding={"0 .4em 0 0"}
                                                        margin={"0"}
                                                    >
                                                        <option value={""}>{applicationContext.translator("Year of birth")}</option>
                                                        {yearRange(new Date().getFullYear() - 90, new Date().getFullYear() - 18).map((year, index) => {
                                                            return <option key={index} value={year} >{year}</option>
                                                        })}
                                                    </Dropdown>
                                                    <Dropdown
                                                        name={"month_of_birth"}
                                                        onChange={handleChange}
                                                        value={values.month_of_birth}
                                                        invalid={touched.month_of_birth && errors.month_of_birth}
                                                        noInvalidIcon={true}
                                                        boxClassName={"dropdown month-dropdown"}
                                                        padding={"0 .2em"}
                                                        margin={"0"}
                                                    >
                                                        <option value={""}>{applicationContext.translator("Month of birth")}</option>
                                                        <option value={1} >{applicationContext.translator("January")}</option>
                                                        <option value={2} >{applicationContext.translator("February")}</option>
                                                        <option value={3} >{applicationContext.translator("March")}</option>
                                                        <option value={4} >{applicationContext.translator("April")}</option>
                                                        <option value={5} >{applicationContext.translator("May")}</option>
                                                        <option value={6} >{applicationContext.translator("June")}</option>
                                                        <option value={7} >{applicationContext.translator("July")}</option>
                                                        <option value={8} >{applicationContext.translator("August")}</option>
                                                        <option value={9} >{applicationContext.translator("September")}</option>
                                                        <option value={10} >{applicationContext.translator("October")}</option>
                                                        <option value={11} >{applicationContext.translator("November")}</option>
                                                        <option value={12} >{applicationContext.translator("December")}</option>
                                                    </Dropdown>
                                                    <Dropdown
                                                        name={"day_of_birth"}
                                                        onChange={handleChange}
                                                        value={values.day_of_birth}
                                                        invalid={touched.day_of_birth && errors.day_of_birth}
                                                        noInvalidIcon={true}
                                                        boxClassName={"dropdown day-dropdown"}
                                                        padding={"0 0 0 .4em"}
                                                        margin={"0"}
                                                    >
                                                        <option value={""}>{applicationContext.translator("Day of birth")}</option>
                                                        {dayRange(values.month_of_birth, applicationContext.state.locale).map((day, index) => {
                                                            return <option key={index} value={day} >{day}</option>
                                                        })}
                                                    </Dropdown>
                                                </Flex>
                                                {errors && errors['date_of_birth'] && <InputError hidden={errors['date_of_birth'] === undefined && true}>{errors['date_of_birth']}</InputError>}


                                            </Col>
                                            <hr style={{ marginTop: "1em" }}/>
                                            <Col md={6}>
                                                <InputBox type="password"
                                                          name={"password"}
                                                          onChange={handleChange}
                                                          label={applicationContext.translator("Password")}
                                                          value={values.Password}
                                                          save={"off"}
                                                          errors={errors}
                                                          touched={touched}
                                                          strength={true}
                                                          onCallbackStrength={(passwordHelp) => {
                                                              this.setState({
                                                                  passwordHelp
                                                              })
                                                          }}
                                                />
                                                <InputBox type="password"
                                                          name={"password_confirmation"}
                                                          onChange={handleChange}
                                                          label={applicationContext.translator("Password Confirm")}
                                                          value={values.password_confirmation}
                                                          save={"off"}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <ProfilePasswordHelp>
                                                    {this.state.passwordHelp}
                                                </ProfilePasswordHelp>
                                            </Col>
                                        </Row>
                                        <Form.Group className={"text-center"}>
                                            <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} radius={10} type={"submit"}>{applicationContext.translator("Complete Registration")}</Button>
                                        </Form.Group>

                                    </Form>
                                )}
                        </Formik>)}
                    </RegisterContainer>
                </Container>
            </React.Fragment>


        );
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(RegisterCompletion))));