import api from "./api";

const fetchCommonData = () => {
    return api.get("/common/all").then((response) => {
        return response.data;
    });
};

const CommonService = {
    fetchCommonData
};

export default CommonService;