import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {withAdminContext} from "../../contexts/AdminContext";
import {BaseContainer, Container, SideBar, Main, HeaderLogoName, Header, HeaderLogo, HeaderMenuIcon,
    MenuItems, MenuItem, MenuItemProfile, ProfileAvatar, ProfileInfo, Body, MenuItemLink, LogoutButton, HeaderWrapper, MenuItemCategory, MenuItemCounter} from "./style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {
    ROUTE_ADMIN_DEPOSITS_RANKING_REPORTS,
    ROUTE_ADMIN_DEPOSITS_REPORTS,
    ROUTE_ADMIN_DRAW_HISTORY, ROUTE_ADMIN_ENTER_WINNING_NUMBER, ROUTE_ADMIN_LOGIN, ROUTE_ADMIN_MONTHLY_YEARLY_OVERVIEW,
    ROUTE_ADMIN_OVERVIEW, ROUTE_ADMIN_PENDING_DEPOSITS_HISTORY, ROUTE_ADMIN_PLAYERS, ROUTE_ADMIN_PLAYERS_CHARGE,
    ROUTE_ADMIN_PLAYERS_TICKETS, ROUTE_ADMIN_SEARCH, ROUTE_ADMIN_SEND_EMAILS, ROUTE_ADMIN_TICKET_BUYING_RANKING_REPORTS,
    ROUTE_ADMIN_WINNERS_HISTORY, ROUTE_HOME
} from "../../routes/routes";
import withRouter from "../../contexts/withRouter";
import Loading from "../../components/ui/Loading";
import {PopupText} from "../../components/ui/AppHeader/style";
import Button from "../../components/ui/Button";


class LoggedIn extends Component {
    constructor(props) {
        super(props);
        const {applicationContext} = this.props;
        this.state = {
            menu: "opened",
            items: []
        }
    }
    componentDidMount() {
        const {applicationContext, adminContext} = this.props;
        this.handleWindowSize();
        this.setState({
            items: [
                {category: applicationContext.translator("Navigation")},
                {title: applicationContext.translator("Search"), route: ROUTE_ADMIN_SEARCH},
                {title: applicationContext.translator("Overview"), route: ROUTE_ADMIN_OVERVIEW},
                {title: applicationContext.translator("Yearly and monthly overview"), route: ROUTE_ADMIN_MONTHLY_YEARLY_OVERVIEW},
                {title: applicationContext.translator("Monthly and yearly deposits"), route: ROUTE_ADMIN_DEPOSITS_REPORTS},
                {title: applicationContext.translator("Waiting for approvals deposits"), route: ROUTE_ADMIN_PENDING_DEPOSITS_HISTORY, counter: 'waitingForApprovalDeposits'},
                {category: ""},
                {title: applicationContext.translator("All Players A/Z"), route: ROUTE_ADMIN_PLAYERS},
                {title: applicationContext.translator("Players Tickets"), route: ROUTE_ADMIN_PLAYERS_TICKETS},
                {title: applicationContext.translator("Draws History"), route: ROUTE_ADMIN_DRAW_HISTORY},
                {category: ""},
                {title: applicationContext.translator("Ranking of deposits"), route: ROUTE_ADMIN_DEPOSITS_RANKING_REPORTS},
                {title: applicationContext.translator("Ranking of ticket buying"), route: ROUTE_ADMIN_TICKET_BUYING_RANKING_REPORTS},
                {category: ""},

                {title: applicationContext.translator("Enter winning number"), route: ROUTE_ADMIN_ENTER_WINNING_NUMBER},
                {title: applicationContext.translator("Charge player account"), route: ROUTE_ADMIN_PLAYERS_CHARGE},
                {title: applicationContext.translator("Send email to players"), route: ROUTE_ADMIN_SEND_EMAILS},
            ]
        }, () => {
            window.addEventListener('resize', this.handleWindowSize, true);
        })

    }
    handleWindowSize = (e) => {
        this.setState({
            menu: (window.innerWidth <= 700)?"closed":"opened"
        })

    }
    onMenuBar = (e) => {
        this.setState({
            menu: (this.state.menu === "opened")?"closed":"opened"
        });
    }
    onMenuClick = (route) => {
        const {location, navigate} = this.props;
        if(location.pathname === route)
            navigate(0);

    }

    onLogout = (e) => {
        e.preventDefault();
        const {applicationContext} = this.props;
        let component = (<PopupText>{applicationContext.translator("Are you sure to logout your account?")}</PopupText>);
        let footer = (
            <React.Fragment>
                <Button color={"success"} onClick={this.handleLogout} margin={"0 .2em"}>{applicationContext.translator("Yes, I'm sure")}</Button>
                <Button color={"danger"} onClick={applicationContext.onCloseConfirmPopup} margin={"0 .2em"}>{applicationContext.translator("No")}</Button>
            </React.Fragment>

        );
        applicationContext.onOpenConfirmPopup("Log Out", component, footer)

    }
    handleLogout = (e) => {
        const {applicationContext, adminContext, navigate} = this.props;
        adminContext.handleLogout(() => {
            applicationContext.onCloseConfirmPopup();
            navigate(ROUTE_ADMIN_LOGIN);
        })
    }

    render() {
        const {adminContext, applicationContext, children, location, loading} = this.props;
        return (
            <Container>
                <BaseContainer>
                    <SideBar className={this.state.menu}>
                        <HeaderLogo>
                            <HeaderLogoName>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}/>
                                <div>
                                    {applicationContext.translator("Lotto 6-46")}
                                    <br/>
                                    <small>{applicationContext.translator("Admin Panel")}</small>
                                </div>
                            </HeaderLogoName>
                            <HeaderMenuIcon onClick={this.onMenuBar}>
                                <FontAwesomeIcon icon={"bars"} />
                            </HeaderMenuIcon>
                        </HeaderLogo>
                        <MenuItems>
                            <MenuItemProfile>
                                <ProfileAvatar>
                                    <img src={"/assets/images/avatar.svg"} />
                                </ProfileAvatar>
                                <ProfileInfo>
                                    {adminContext.state.userInfo['full_name']}<br/>
                                    <span>{adminContext.state.userInfo['role'][0]}</span>
                                </ProfileInfo>
                                <LogoutButton onClick={this.onLogout}>
                                    <FontAwesomeIcon icon={"power-off"} />
                                </LogoutButton>
                            </MenuItemProfile>
                            {this.state.items.map((menu, index) => {
                                if(menu.hasOwnProperty('category')){
                                    return (
                                        <MenuItemCategory>
                                            {menu.category}
                                        </MenuItemCategory>
                                    )
                                }else{
                                    return (
                                        <MenuItem active={location.pathname === menu.route}>
                                            <NavLink to={menu.route} onClick={(e) => this.onMenuClick(menu.route)}>
                                                <MenuItemLink>
                                                    <div>{menu.title}</div>
                                                    {menu.hasOwnProperty('counter') && adminContext.state.counter.hasOwnProperty(menu.counter) && adminContext.state.counter[menu.counter] > 0 &&
                                                    <MenuItemCounter>{adminContext.state.counter[menu.counter]}</MenuItemCounter>}

                                                </MenuItemLink>
                                            </NavLink>
                                        </MenuItem>
                                    )
                                }
                            })}
                        </MenuItems>
                    </SideBar>
                    <Main sidebar={this.state.menu}>
                        <Header>
                            {this.state.menu === "closed" &&
                            <HeaderMenuIcon center={true} onClick={this.onMenuBar}>
                                <FontAwesomeIcon icon={"bars"} />
                            </HeaderMenuIcon>
                            }
                            <HeaderWrapper>

                            </HeaderWrapper>

                        </Header>
                        <Body>
                            {loading?<Loading animation={true} transparent={true} />:children}
                        </Body>
                    </Main>
                </BaseContainer>

            </Container>
        );
    }
}

export default withApplicationContext(withAdminContext(withRouter(LoggedIn)));