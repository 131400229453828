import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const DrawResult = styled.div`
  color: var(--color-text);
  margin-top: 1em;
`
export const DrawResultItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`