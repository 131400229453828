import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  ${(props) => props.margin && `
    margin: 1em 0;
  `};
  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
  }
`;
export const WinnerIcon = styled.div`
  width: 84px;
  height: 84px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f3f3f3;
  border-radius: 100%;
  ${(props) => props.image && `
    background-image: url(${props.image}) 
  `};
  @media screen and (max-width: 700px) {
    margin: 0 auto 1em auto;
  }
`
export const WinnerInfo = styled.div`
  margin: 0 1em;
  font-size: 1.2em;
  font-weight: 600;
  & span{
    display: block;
  }
`
export const WinnerName = styled.h5`
  margin: 0;
  color: var(--color-secondary);
  font-size: 1em;
`

