import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {
    SectionContainer,
    StepsContainer,
    StepDescription,
    StepsTitle,
    StepsList, StepItem, StepNumber,
    StepSubDescription
} from "./style.jsx";
import {Container, Row, Col, NavLink} from 'react-bootstrap';

class Steps extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer>
                <Container>
                    <StepsContainer>
                        <Row>
                            <Col md={12}>
                                <StepsTitle>{applicationContext.translator("Play Lotto in 5 simple steps")}</StepsTitle>
                                <StepsList>
                                    <StepItem>
                                        <StepNumber>1</StepNumber>
                                        <StepDescription dangerouslySetInnerHTML={{__html: applicationContext.translator("Choose the number of lottery tickets you want to play with")}} />
                                    </StepItem>
                                    <StepItem>
                                        <StepNumber>2</StepNumber>
                                        <StepDescription dangerouslySetInnerHTML={{__html: applicationContext.translator("Pick your 6 lucky number combinations from 1-46")}} />
                                    </StepItem>
                                    <StepItem>
                                        <StepNumber>3</StepNumber>
                                        <StepDescription dangerouslySetInnerHTML={{__html: applicationContext.translator("Select number of draws you want play")}} />
                                    </StepItem>
                                    <StepItem>
                                        <StepNumber>4</StepNumber>
                                        <StepDescription dangerouslySetInnerHTML={{__html: applicationContext.translator("Check your order and purchase your ticket(s)")}} />
                                    </StepItem>
                                    <StepItem full={true}>
                                        <StepNumber>5</StepNumber>
                                        <StepDescription>
                                            {applicationContext.translator("Dream big and be millionaire, enjoy life when you hit the jackpot price.")}
                                            <StepSubDescription>{applicationContext.translator("Good luck friday. we keep our fingers crossed for you")}</StepSubDescription>
                                        </StepDescription>

                                    </StepItem>


                                </StepsList>

                            </Col>
                        </Row>
                    </StepsContainer>
                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(Steps);