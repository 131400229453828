import styled from "styled-components";
import {Button} from "react-bootstrap"
export const ButtonContainer = styled(Button)`
  ${(props) => props.color === "primary" && `
     background: var(--color-primary);
     border-color: var(--color-primary);
     color: #333;
     &:hover{
        background: var(--color-background);
       border-color: var(--color-secondary);
       color: var(--color-secondary);
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(241 204 1 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  var(--color-secondary) !important;
        color: #fff !important;;
        background: var(--color-secondary) !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "black" && `
     background: #333;
     border-color: #333;
     color: #fff;
     &:hover{
        background: transparent;
       border-color: #333;
       color: #333;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  #333 !important;
        color: #fff !important;;
        background: #333 !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: #333;
        border-color: #333;
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "black-primary" && `
     background: #333;
     border-color: #333;
     color: #fff;
     &:hover{
        background: var(--color-secondary);
       border-color: var(--color-secondary);
       color: #333;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(241 204 1 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  var(--color-secondary) !important;
        color: #fff !important;;
        background: var(--color-secondary) !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: #333;
        border-color: #333;
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "success" && `
      background: #4caf50;
      border-color: #4caf50;
      color: #fff;
     &:hover{
        background: #fff;
       border-color: #4caf50;
       color: #4caf50;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(76 175 80 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  #4caf50 !important;
        color: #fff !important;;
        background: #4caf50 !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: #4caf50;
        border-color: #4caf50;
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "danger" && `
      background: #d72424;
      border-color: #d72424;
      color: #fff;
     &:hover{
        background: #fff;
       border-color: #d72424;
       color: #d72424;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(215 36 36 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  #d72424 !important;
        color: #fff !important;;
        background: #d72424 !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: #d72424;
        border-color: #d72424;
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.fullWidth && `width: 100%;`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.size && props.size === 2 && `
    padding: 0.7em 1em;
    font-size: 1.2em;
    font-weight: 600;
  `}
  ${(props) => props.size && props.size === 3 && `
    padding: 0.5em 1em;
    font-size: 1.2em;
  `}
  border-radius: var(--form-element-border-radius);
  
  
`;
