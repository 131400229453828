import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
export const Container = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%);
  margin: 1em auto;
  align-content: center;
  color: #333;
  min-height: 132px;
  position: relative;
  transform: none;
  max-width: 350px;
  &.delete{
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-300px);
    transition: all 0.6s ease-out;

  }
  ${(props) => props.error && `
        border: 1px solid #dc3545;
        box-shadow: 0 5px 15px 0 rgb(251 0 0 / 20%);
        color: #dc3545;
        transition: all 0.6s ease-out;
  `}
`;

export const Wrapper = styled.div`
    height: 100%;
  display: flex;
`
export const Body = styled.div`
  border-left: 1px solid #ececec;
  padding: 1em 0.5em;
  flex-grow: 1;
`
export const Toolbar = styled.div`
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0.5em;
  position: relative;
  min-width: 45px;
  max-width: 45px;
  
`
export const ToolbarButton = styled.button`
  background: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a2a2a2;
  ${(props) => props.right && `
     justify-content: flex-end;
  `}
  &:focus, &:active{
    border: none;
    border-color: #fff !important;
    box-shadow: none;
  }
  &:hover{
    color: #333;
    transition: all ease-in-out .2s;
  }
  & span{
    display: block;
    font-size: .8em;
  }
`
export const ToolbarButtonWrapper = styled.div`
    
`;
export const IconButton = styled.button`
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a2a2a2;
  & svg{
    font-size: 1.2em;  
  }
  &:hover{
    color: var(--color-primary);
    transition: color .5s ease;
  }
  
  
`
export const Icon = styled(FontAwesomeIcon)`
  margin: 0;
  ${(props) => props.rotate && `
        transition: transform .3s ease,color .5s ease;
  `}
  &:hover{
    ${(props) => props.rotate && `
        transform: rotate(180deg);
        transition: transform .3s ease;
   `}
    
  }
`;
export const NameContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Name = styled.div`
  ${(props) => props.small && `
    font-size: .9em;
  `}
`;
export const Price = styled.div`
  
`;
export const BallContainer = styled.div`
    display: flex;
    ${(props) => props.margin?`
        margin: ${props.margin};
    `:`
        margin: 1em 0;
   `}
    
    justify-content: space-between;
    align-items: center;
    direction: ltr;
`;
export const Ball = styled.div`
    background: #f2f2f2;
    border-radius: 50%;
    box-shadow: inset 0 0 1px rgb(0 0 0 / 40%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 700;
    height: 35px;
    line-height: 1;
    position: relative;
    width: 35px;
    color: #333;
    
    margin: 0.1em;
    & span{
      position: relative;
      &:after{
        background: #000;
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        margin-top: 2px;
      }
    }
    ${(props) => props.list && `
       margin: .38em;
       background: transparent;
       &:hover{
          background: #fd0;
          background-image: radial-gradient(circle 11.66667px at 26% 26%,hsla(0,0%,100%,.8) 5%,hsla(0,0%,100%,0) 60%),radial-gradient(circle farthest-side at 26% 26%,#ffe74d 0,#ffe74d 40%,#fd0 60%,#fd0 70%,#e6b400 110%);
          box-shadow: none;
          transition: all ease-in-out 1s;
       }
    `}
    ${(props) => props.fill && `
      background: #fd0;
      background-image: radial-gradient(circle 11.66667px at 26% 26%,hsla(0,0%,100%,.8) 5%,hsla(0,0%,100%,0) 60%),radial-gradient(circle farthest-side at 26% 26%,#ffe74d 0,#ffe74d 40%,#fd0 60%,#fd0 70%,#e6b400 110%);
      box-shadow: none;
    `}

    ${(props) => props.highlight && `
        background: #0ac700 !important;
        background-image: none !important;
        color: #fff;
        & span:after{
            background: #fff !important;
        }
   `}
    ${(props) => props.editable && `
        cursor: pointer;
   `}
    ${(props) => props.reserve && `
        background: var(--color-ball-reserve-background) !important;
        background-image: none !important;
        color: var(--color-ball-reserve-text);
        & span:after{
            background: #fff !important;
        }
   `}
    

`;
export const ChooseNumberButton = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  font-size: .9em;
  width: 100%;
  position: relative;
  &:hover{
    color: var(--color-primary);
    transition: all ease-in-out .3s;
  }
`;
export const ContainerWrapper = styled.div`
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const NumbersListContainer = styled.div`
  width: 100%;
  background: #fff;
  border-top: 1px solid #ececec;
  border-bottom-left-radius: 10px;
  box-shadow: 0 15px 15px 0 rgb(0 0 0 / 15%);
  border-bottom-right-radius: 10px;
  position: absolute;
  margin-top: -8px;
  z-index: 99;
`
export const NumbersListToolbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ececec;
`;
export const NumbersList = styled.div`
  margin: 1em;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  direction: ltr;
`
export const NumberCircle = styled.li`
  
`
export const TicketID = styled.div`
  transform: rotate(270deg);
  position: absolute;
  top: 5.4em;
  letter-spacing: 2px;
  margin-left: 0.5em;
  font-size: .9em;
  font-weight: 600;
  width: 126px;
  direction: ltr;
`
export const TicketDate = styled.div`
  font-size: .85em;
  font-weight: 500;
  color: #707070;
`

export const ErrorText = styled.div`
  color: #dc3545;
  position: absolute;
  bottom: -25px;
  right: 3px;
  font-size: .9em;
  font-weight: 500;
`