import React, {Component} from 'react';
import {withApplicationContext} from "../../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../../contexts/withRouter";
import {withAdminContext} from "../../../../contexts/AdminContext";
import {OverviewContainer} from "./style";
import LoggedIn from "../../LoggedIn";
import withAdminAuth from "../../../../contexts/withAdminAuth";
import AdminService from "../../../../services/AdminService";
import {accountId, convertToDate, ticketId} from "../../../../helpers";
import DataList from "../../../../components/ui/DataList/DataList";
import Search from "./Search";

class Deposits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            search: {
                type: "monthly",
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1
            },
            sort: {
                key: 'id',
                dir: 'desc'
            }
        }

    }
    componentDidMount = async () => {
        await this.fetchData(1);
        this.setState({ loading: false })
    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        let data = await AdminService.totalDeposits(search??this.state.search);
        this.setState({
            loadingData: false,
            data: data,
            search: search??this.state.search,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} doFetchData={this.fetchData}/>;
    }
    renderDate = (value) => {
        const {applicationContext} = this.props;
        if(this.state.search.type === "yearly")
            return convertToDate(value, applicationContext.state.locale, 'YYYY/MM/DD HH:mm:ss', "MMMM YYYY");
        return convertToDate(value, applicationContext.state.locale, 'YYYY/MM/DD HH:mm:ss', "ddd, MMMM DD,YYYY");
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number"},
                        {key: "date", label: "Date", render: this.renderDate},
                        {key: 'total_count', label: "Total Transactions", format: "numberCommas"},
                        {key: "total_amount", label: "Total Deposits", format: "amountColor"},
                        {key: 'pending', label: "Wait approval", format: "numberCommas"},
                        {key: 'approved', label: "Total Approved", format: "numberCommas"},
                        {key: 'declined', label: "Total Declined", format: "numberCommas"},
                    ]}
                    sum={['total_count', 'total_amount', 'pending', 'approved', 'declined']}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                    footer={true}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(Deposits)))));