import React, {Component} from 'react';
import {InputContainer, Container, InputError} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {arrayRange} from "../../../helpers";
import InputBox from "../InputBox/InputBox";
class InputNumberBox extends Component {
    inputs = [];

    constructor(props) {
        super(props);
        this.state = {
            numbers: []
        }
    }

    onChange = (e, index) => {
        const {handleChange, count, applicationContext} = this.props;
        let numbers = [];
        this.inputs.map((input) => {
            numbers.push(input.value())
        })
        return this.setState({
            numbers
        }, () => {
            if(applicationContext.translator("direction") === "rtl"){
                if(e.target.value.length === 1 && (index - 1) >= 0){
                    this.inputs[index - 1].select();
                }
                return handleChange(this.state.numbers.reverse().join(''));
            }else{
                if(e.target.value.length === 1 && (index + 1) <= count){
                    this.inputs[index + 1].select();
                }
                return handleChange(this.state.numbers.join(''));
            }



        })


    }
    render() {
        const {applicationContext, count, handleChange, name, errors, touched} = this.props;
        return (
            <Container>
                <InputContainer>
                    {arrayRange(1, count).map((c, index) => {
                        return (
                            <InputBox type="text"
                                      name={name}
                                      onChange={(e) => this.onChange(e, index)}
                                      maxLength={1}
                                      invalid={touched && touched[name] && (errors[name] !== undefined)}
                                      onlyNumber={true}
                                      center={true}
                                      ref={(ref) => this.inputs[index] = ref}
                                      margin={"0"}
                                      save={"off"}
                                      fontSize={"1.3em"}
                                      fontWeight={"600"}
                                      padding={"0.8em .3em"}
                            />
                        )
                    })}
                </InputContainer>
                {touched && touched[name] && <InputError hidden={errors[name] === undefined && true}>{errors[name]}</InputError>}
            </Container>
        );
    }
}
InputNumberBox.propTypes = {
    count: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
}
export default withApplicationContext(InputNumberBox);