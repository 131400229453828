import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {toDate} from "../../../helpers";
import {TicketCreatingProvider} from "../../../contexts/TicketCreatingContext";
import Ticket from "./Ticket";
class ShowTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const {applicationContext, ticket, prize} = this.props;
        const {numbers, id, price, created_at} = ticket;
        return (
            <TicketCreatingProvider value={{
                state: this.state
            }}>
                <Ticket numbers={numbers}
                        id={id}
                        selected={ticket.draw && ticket.draw['won_number']}
                        prize={prize}
                        date={toDate(created_at, applicationContext.state.locale, true, false)}
                />
            </TicketCreatingProvider>
        );
    }
}
ShowTicket.propTypes = {
    ticket: PropTypes.object,
    prize: PropTypes.string
}
export default withApplicationContext((ShowTicket));