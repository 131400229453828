import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import withAuth from "../../../contexts/withAuth";
import LoggedIn from "../LoggedIn";
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import {DrawResult, DrawResultItem} from "./style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import Card from "../../../components/ui/Card";
import {convertToDate, numberWithCommas, toSearchDataList} from "../../../helpers";
import SearchDropdown from "../../../components/ui/SearchDropdown/SearchDropdown";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AdminService from "../../../services/AdminService";
import AccountService from "../../../services/AccountService";
import SuccessMessage from "../../../components/ui/SuccessMessage";

class EnterWinningNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            loading: true,
            success: false,
            drawDone: null,
            draws: [],
            form: {
                draw_id: "",
                number1: "",
                number2: "",
                number3: "",
                number4: "",
                number5: "",
                number6: "",
                reserve: ""
            }
        }

    }
    componentDidMount = async () => {
        const {applicationContext, adminContext} = this.props;
        let form = this.state.form;
        await AdminService.draws({
            sort_field: 'date',
            sort_dir: 'asc',
            limit: 10,
            status: 'pending'
        }).then((draws) => {
            let _list = [];
            let draw_id = "";
            draws.data.forEach(function (obj){
                let persianDate = convertToDate(obj['date'],  "fa", 'YYYY-MM-DD', 'dddd, D MMMM YYYY');
                _list.push({
                    labelKey: obj['id'],
                    value: obj['date'] + " ("+persianDate+")" + " #" + obj['num_of_draw'],
                    isSelected: false
                })

            });
            if(_list.length > 0) {
                _list[_list.length - 1].isSelected = true
                draw_id = _list[_list.length - 1].labelKey;
            }
            this.setState({
                draws: _list,
                form: {
                    ...this.state.form,
                    draw_id
                },
                loading: false
            })
        })
    }
    onSubmit = async (values, actions) => {
        const {applicationContext, alert} = this.props;

        this.setState({ submitting: true });
        let data = {
            draw_id: values.draw_id,
            numbers: [values.number1, values.number2, values.number3, values.number4, values.number5, values.number6],
            reserve: values.reserve
        }
        await AdminService.draw(data).then((data) => {
            if(data){
                console.log(data)
                this.setState({
                    success: applicationContext.translator("The entered numbers were registered and the lottery was successfully completed"),
                    drawDone: data
                })
            }
        }).catch((e) => {
            if(e.response.data.hasOwnProperty('errors') && Object.keys(e.response.data.errors).length > 0){
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
                })
            }else{
                alert.error(applicationContext.translator(e.response.data.message));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        });
    }

    render() {
        const {applicationContext, adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state.form}
                    validationSchema={Yup.object().shape({
                        draw_id: Yup.number().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Draw")),
                        number1: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("${path} must be digit between 1 to 46"), (value) => value > 0 && value <= 46)
                            .test('duplicate', applicationContext.translator("${path} already exists in numbers"), (value, context) => {
                                let numbers = [context.parent.number2, context.parent.number3, context.parent.number4, context.parent.number5, context.parent.number6, context.parent.reserve];
                                return (!numbers.includes(value))
                            })
                            .label(applicationContext.translator("Number 1")),
                        number2: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("${path} must be digit between 1 to 46"), (value) => value > 0 && value <= 46)
                            .test('duplicate', applicationContext.translator("${path} already exists in numbers"), (value, context) => {
                                let numbers = [context.parent.number1, context.parent.number3, context.parent.number4, context.parent.number5, context.parent.number6, context.parent.reserve];
                                return (!numbers.includes(value))
                            })
                            .label(applicationContext.translator("Number 2")),
                        number3: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("${path} must be digit between 1 to 46"), (value) => value > 0 && value <= 46)
                            .test('duplicate', applicationContext.translator("${path} already exists in numbers"), (value, context) => {
                                let numbers = [context.parent.number1, context.parent.number2, context.parent.number4, context.parent.number5, context.parent.number6, context.parent.reserve];
                                return (!numbers.includes(value))
                            })
                            .label(applicationContext.translator("Number 3")),
                        number4: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("${path} must be digit between 1 to 46"), (value) => value > 0 && value <= 46)
                            .test('duplicate', applicationContext.translator("${path} already exists in numbers"), (value, context) => {
                                let numbers = [context.parent.number1, context.parent.number2, context.parent.number3, context.parent.number5, context.parent.number6, context.parent.reserve];
                                return (!numbers.includes(value))
                            })
                            .label(applicationContext.translator("Number 4")),
                        number5: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("${path} must be digit between 1 to 46"), (value) => value > 0 && value <= 46)
                            .test('duplicate', applicationContext.translator("${path} already exists in numbers"), (value, context) => {
                                let numbers = [context.parent.number1, context.parent.number2, context.parent.number3, context.parent.number4, context.parent.number6, context.parent.reserve];
                                return (!numbers.includes(value))
                            })
                            .label(applicationContext.translator("Number 5")),
                        number6: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("${path} must be digit between 1 to 46"), (value) => value > 0 && value <= 46)
                            .test('duplicate', applicationContext.translator("${path} already exists in numbers"), (value, context) => {
                                let numbers = [context.parent.number1, context.parent.number2, context.parent.number3, context.parent.number4, context.parent.number5, context.parent.reserve];
                                return (!numbers.includes(value))
                            })
                            .label(applicationContext.translator("Number 6")),
                        reserve: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('duplicate', applicationContext.translator("${path} already exists in numbers"), (value, context) => {
                                let numbers = [context.parent.number1, context.parent.number2, context.parent.number3, context.parent.number4, context.parent.number5, context.parent.number6];
                                return (!numbers.includes(value))
                            })
                            .test('number', applicationContext.translator("${path} must be digit between 1 to 46"), (value) => value > 0 && value <= 46)
                            .label(applicationContext.translator("Reserve Number"))
                    })}
                    onSubmit={this.onSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Card maxWidth={800} center={true} top={"2em"}>
                                {this.state.success?(
                                    <SuccessMessage icon={true} message={this.state.success}>
                                        <DrawResult>
                                            {Object.keys(this.state.drawDone).map((win) => {
                                                return (
                                                    <DrawResultItem>
                                                        <b>{applicationContext.translator(win)}</b>
                                                        <span>{numberWithCommas(this.state.drawDone[win], false, ",")}</span>
                                                    </DrawResultItem>
                                                )
                                            })}
                                        </DrawResult>
                                    </SuccessMessage>
                                ):(
                                <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                    <SearchDropdown
                                        name={"draw_id"}
                                        onChange={(e) => e.selectedKey[0] !== undefined && setFieldValue('state_id', e.selectedKey[0]) }
                                        data={this.state.draws}
                                        value={values.draw_id}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <InputBox type="text"
                                              name={"number1"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Number 1")}
                                              value={values.number1}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type="text"
                                              name={"number2"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Number 2")}
                                              value={values.number2}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type="text"
                                              name={"number3"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Number 3")}
                                              value={values.number3}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type="text"
                                              name={"number4"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Number 4")}
                                              value={values.number4}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type="text"
                                              name={"number5"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Number 5")}
                                              value={values.number5}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type="text"
                                              name={"number6"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Number 6")}
                                              value={values.number6}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type="text"
                                              name={"reserve"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Reserve Number")}
                                              value={values.reserve}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <Form.Group className={"text-center"}>
                                        <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} className={"btn btn-account"} type={"submit"}>
                                            {applicationContext.translator("OK")}
                                        </Button>
                                    </Form.Group>


                                </Form>)}
                            </Card>
                        )}
                </Formik>
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(EnterWinningNumber)))));