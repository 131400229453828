import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const Status = styled.span`
  ${(props) => props.status && props.status === "pending" && `
    color : #dba400;
  `};
  ${(props) => props.status && props.status === "approved" && `
    color : #4caf50;
  `};
  ${(props) => props.status && props.status === "rejected" && `
    color : #f44336;
  `};
`