import React, {Component} from 'react';
import {Container, WinnerIcon, WinnerInfo, WinnerName} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import jalaali from 'jalaali-js';
import {DrawResultTitle} from "../../../scenes/Home/style";
import moment from 'jalali-moment'

class PersianDate extends Component {

    render() {
        const {date, applicationContext} = this.props;
        return (
            <React.Fragment>
                {moment(date, 'YYYY/MM/DD').locale(applicationContext.state.locale).format('dddd, D MMMM YYYY')}
            </React.Fragment>
        );
    }
}
PersianDate.propTypes = {
    date: PropTypes.any
}
export default withApplicationContext(PersianDate);