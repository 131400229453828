import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const Text = styled.p`
  margin: 1em 0;
  text-align: justify;
  
`
export const CopyIcon = styled.div`
  
`