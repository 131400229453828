import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  background: var(--color-primary);
  padding: 1em 0;
  margin: 0 0 2em 0;
`;
export const Items = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  @media screen and (max-width: 700px) {
    display: block !important;
  }
`;
export const Item = styled.li`
  width: 100%;
  font-size: 1.1em;
  font-weight: 700;
  text-align: center;
  @media screen and (max-width: 700px) {
    margin: 1em 0;
  }
`;
