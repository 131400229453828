import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
  padding: 0.3em 1em 0.3em 1.4em;
  border-radius: 12px;
  font-size: 1.8em;
  font-weight: 600;
  letter-spacing: 13px;
  direction: ltr;
  border: 2px solid ${(props) => (props.borderColor)??`var(--color-primary)`};
  ${(props) => props.margin && `
    margin: 1em 0;
  `};
  @media screen and (max-width: 700px) {
    font-size: 1.5em;
    margin: 1em auto;
  }
`;
