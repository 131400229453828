import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AdminService from "../../../services/AdminService";
import SearchTable from "../../../components/ui/SearchTable";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            draws: [],
            loading: true
        }
    }
    componentDidMount = async () => {
        await AdminService.draws({
            sort_field: 'date',
            sort_dir: 'asc',
            limit: 10,
            status: 'done'
        }).then((draws) => {
            this.setState({
                draws: draws.data,
                loading: false
            })
        })
    }
    render() {
        const {doFetchData} = this.props;
        return (
            <SearchTable
                loading={this.state.loading}
                search={[
                    {id: 'id', type: 'textbox', label: "Search by ticket id", onlyNumber: true},
                    {id: 'user_id', type: 'textbox', label: "Search by account id", onlyNumber: true},
                    {id: 'draw_id', type: 'dropdown_searchable', data: this.state.draws, parse: 'parseDrawItems'},
                ]}
                sort={{
                    field: 'id',
                    dir: 'asc'
                }}
                doFetchData={doFetchData}
            />
        );
    }
}
Search.propTypes = {
    data: PropTypes.any,
    doFetchData: PropTypes.func
}
export default withApplicationContext(withRouter(withAdminContext(withAdminAuth(Search))));