import React, {Component} from 'react';
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import {BlockText, FormTitle} from "../style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button/Button";
import {NavLink} from "react-router-dom";
import {ROUTE_LOGIN} from "../../../routes/routes";
import {Formik} from "formik";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from "prop-types";

class EmailForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
    }
    render() {
        const {applicationContext, onSubmit, loginHint, submitting, submitLabel} = this.props;
        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                initialValues={{
                    email: this.state.email
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address"))
                })}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) =>
                    (
                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                            <FormTitle>{applicationContext.translator("Enter your email and follow the instructions sent to you by email.")}</FormTitle>
                            <InputBox type="text"
                                      name={"email"}
                                      onChange={handleChange}
                                      placeholder={applicationContext.translator("E-Mail Address")}
                                      value={values.email}
                                      save={"off"}
                                      focus={true}
                                      errors={errors}
                                      touched={touched}
                            />
                            <Form.Group className={"text-center"}>
                                <Button color={"primary"} fullWidth={true} size={3} submitting={submitting} radius={10} className={"btn btn-account"} type={"submit"}>{submitLabel??applicationContext.translator("Register")}</Button>
                            </Form.Group>

                            <br/>
                            {loginHint && <Form.Group as={Row} className={"mb-3"}>
                                <Col className={"text-center"}>
                                    <BlockText>
                                        {applicationContext.translator("Do you have an account?")} <NavLink to={ROUTE_LOGIN}>{applicationContext.translator("Click here to login")}</NavLink>
                                    </BlockText>
                                </Col>
                            </Form.Group>}

                        </Form>
                    )}
            </Formik>
        );
    }
}
EmailForm.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    loginHint: PropTypes.bool,
    submitLabel: PropTypes.string
}
export default withApplicationContext(EmailForm);