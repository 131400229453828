import React, {Component} from 'react';
import {HeaderBar,
    HeaderBarWrapper,
    HeaderLogo,
    HeaderLeft,
    HeaderRight,
    HeaderCenter,
    HeaderMenu,
    MenuBar,
    MenuBarItem,
    HeaderTextMenu,
    TextChanceWeekly,
    TextDrawWeeklyTime,
    VerticalLine,
    FloatMenu,
    FloatMenuContainer,
    FloatMenuItems,
    FloatMenuItem,
    CloseMenu,
    FloatMenuIcon,
    HeaderBalance,
    PopupText
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import JackpotPrice from "../JackpotPrice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    ROUTE_ABOUT,
    ROUTE_ACCOUNT,
    ROUTE_ACCOUNT_MY_TICKETS,
    ROUTE_ACCOUNT_PROFILE,
    ROUTE_BUY_TICKET, ROUTE_FAQ, ROUTE_GAME_MANUAL,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_REGISTER, ROUTE_REGISTER_DRAW_RESULTS
} from "../../../routes/routes";
import {NavLink} from "react-router-dom";
import withRouter from "../../../contexts/withRouter";
import {isRouteActive} from "../../../helpers";
import Button from "../Button";
import {withAccountContext} from "../../../contexts/AccountContext";
class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
    }
    onClickMenuIcon = (e) => {
        this.setState({
            menu: !this.state.menu
        })
    }
    onLogout = (e) => {
        e.preventDefault();
        const {applicationContext} = this.props;
        let component = (<PopupText>{applicationContext.translator("Are you sure to logout your account?")}</PopupText>);
        let footer = (
            <React.Fragment>
                <Button color={"success"} onClick={this.handleLogout} margin={"0 .2em"}>{applicationContext.translator("Yes, I'm sure")}</Button>
                <Button color={"danger"} onClick={applicationContext.onCloseConfirmPopup} margin={"0 .2em"}>{applicationContext.translator("No")}</Button>
            </React.Fragment>

        );
        applicationContext.onOpenConfirmPopup("Log Out", component, footer)

    }
    handleLogout = (e) => {
        const {applicationContext, accountContext, navigate} = this.props;
        accountContext.handleLogout(() => {
            applicationContext.onCloseConfirmPopup();
            navigate(ROUTE_HOME);
        })
    }
    render() {
        const {applicationContext, accountContext, history} = this.props;
        return (
            <HeaderBar>
                <Container style={{ height: "100%" }}>
                    <HeaderBarWrapper>
                        <FloatMenuIcon icon={"bars"} onClick={this.onClickMenuIcon}/>
                        <FloatMenu display={this.state.menu}>
                            <FloatMenuContainer>
                                <CloseMenu onClick={this.onClickMenuIcon}><FontAwesomeIcon icon={"times"} /></CloseMenu>
                                <FloatMenuItems>
                                    <FloatMenuItem><NavLink to={ROUTE_BUY_TICKET}>{applicationContext.translator("Buy Tickets")}</NavLink></FloatMenuItem>
                                    <FloatMenuItem><NavLink to={ROUTE_REGISTER_DRAW_RESULTS}>{applicationContext.translator("Draw Results")}</NavLink></FloatMenuItem>
                                    <FloatMenuItem><NavLink to={ROUTE_GAME_MANUAL}>{applicationContext.translator("Game Manual")}</NavLink></FloatMenuItem>
                                    <FloatMenuItem><NavLink to={ROUTE_FAQ}>{applicationContext.translator("Support")}</NavLink></FloatMenuItem>
                                    <FloatMenuItem><NavLink to={ROUTE_ACCOUNT_MY_TICKETS}>{applicationContext.translator("My Tickets")}</NavLink></FloatMenuItem>
                                    <FloatMenuItem><NavLink to={ROUTE_ABOUT}>{applicationContext.translator("About Us")}</NavLink></FloatMenuItem>
                                    {accountContext.state.loggedIn && <FloatMenuItem><NavLink to={ROUTE_ACCOUNT}>{applicationContext.translator("My Account")}</NavLink></FloatMenuItem>}
                                    {accountContext.state.loggedIn && <FloatMenuItem><NavLink to={"#"} onClick={this.onLogout}>{applicationContext.translator("Log Out")}</NavLink></FloatMenuItem>}
                                    {!accountContext.state.loggedIn && <FloatMenuItem><NavLink to={ROUTE_REGISTER}>{applicationContext.translator("Register")}</NavLink></FloatMenuItem>}
                                    {!accountContext.state.loggedIn && <FloatMenuItem><NavLink to={ROUTE_LOGIN}>{applicationContext.translator("Log In")}</NavLink></FloatMenuItem>}
                                </FloatMenuItems>
                            </FloatMenuContainer>
                        </FloatMenu>
                        <HeaderLeft>
                            <HeaderMenu>
                                <MenuBar>
                                    <MenuBarItem active={isRouteActive(ROUTE_BUY_TICKET)} first={true}><NavLink to={ROUTE_BUY_TICKET}>{applicationContext.translator("Buy Tickets")}</NavLink></MenuBarItem>
                                    <MenuBarItem active={isRouteActive(ROUTE_REGISTER_DRAW_RESULTS)} ><NavLink to={ROUTE_REGISTER_DRAW_RESULTS}>{applicationContext.translator("Draw Results")}</NavLink></MenuBarItem>
                                    <MenuBarItem active={isRouteActive(ROUTE_GAME_MANUAL)}><NavLink to={ROUTE_GAME_MANUAL}>{applicationContext.translator("Game Manual")}</NavLink></MenuBarItem>
                                    <MenuBarItem active={isRouteActive(ROUTE_FAQ)}><NavLink to={ROUTE_FAQ}>{applicationContext.translator("Support")}</NavLink></MenuBarItem>
                                </MenuBar>
                            </HeaderMenu>
                            <HeaderTextMenu>
                                <JackpotPrice />
                            </HeaderTextMenu>
                        </HeaderLeft>
                        <HeaderCenter>
                            <NavLink to={ROUTE_HOME}><HeaderLogo src={"/assets/images/header-logo.png"} /></NavLink>
                        </HeaderCenter>
                        <HeaderRight>
                            {accountContext.state.loggedIn && <HeaderBalance>{applicationContext.translator("Your Balance")}: <span>-,{accountContext.state.userInfo.balance} $</span></HeaderBalance>}
                            <HeaderMenu>
                                <MenuBar>
                                    <MenuBarItem active={isRouteActive(ROUTE_ACCOUNT_MY_TICKETS)}><NavLink to={ROUTE_ACCOUNT_MY_TICKETS}>{applicationContext.translator("My Tickets")}</NavLink></MenuBarItem>
                                    <MenuBarItem active={isRouteActive(ROUTE_ABOUT)}><NavLink to={ROUTE_ABOUT}>{applicationContext.translator("About Us")}</NavLink></MenuBarItem>
                                    {accountContext.state.loggedIn && <MenuBarItem active={isRouteActive(ROUTE_ACCOUNT)}><NavLink to={ROUTE_ACCOUNT}>{applicationContext.translator("My Account")}</NavLink></MenuBarItem>}
                                    {accountContext.state.loggedIn && <MenuBarItem last={true}><NavLink to={"#"} onClick={this.onLogout}>{applicationContext.translator("Log Out")}</NavLink></MenuBarItem>}
                                    {!accountContext.state.loggedIn && <MenuBarItem active={isRouteActive(ROUTE_REGISTER)}><NavLink to={ROUTE_REGISTER}>{applicationContext.translator("Register")}</NavLink></MenuBarItem>}
                                    {!accountContext.state.loggedIn && <MenuBarItem active={isRouteActive(ROUTE_LOGIN)} last={true}><NavLink to={ROUTE_LOGIN} >{applicationContext.translator("Log In")}</NavLink></MenuBarItem>}
                                </MenuBar>
                            </HeaderMenu>
                            <HeaderTextMenu flex={1}>
                                <TextChanceWeekly>
                                    {applicationContext.translator("Try your luck in the weekly lottery by registering on the site and win 1,000,000,000 Tomans from us.")}
                                </TextChanceWeekly>
                                <VerticalLine></VerticalLine>
                                <TextDrawWeeklyTime>
                                    {applicationContext.translator("Weekly Draw")}<br/>
                                    {applicationContext.translator("Every")}<br/>
                                    {applicationContext.translator("Friday Night @ 21:00")}<br/>
                                </TextDrawWeeklyTime>

                            </HeaderTextMenu>
                        </HeaderRight>
                    </HeaderBarWrapper>
                </Container>
            </HeaderBar>
        );
    }
}

export default withApplicationContext(withRouter(withAccountContext(AppHeader)));