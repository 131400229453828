import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const CenterContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 5em auto;
`
export const ProfilePasswordHelp = styled.div`
  margin-top: 8em;
`