import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import MyAccount from "../MyAccount";
import {getRandomInt, shuffle, toDate} from "../../../helpers";
import {TicketGroup, TicketGroupContainer, TicketGroupDate, TicketSection, Plus} from "./style";
import PersianDate from "../../../components/ui/PersianDate";
import Ticket from "../../../components/ui/Ticket/Ticket";
import {TicketCreatingProvider} from "../../../contexts/TicketCreatingContext";
import {Ball, BallContainer} from "../../../components/ui/Ticket/style";
import withAuth from "../../../contexts/withAuth";
import AccountService from "../../../services/AccountService";
import {NotFound, NotFoundWrapper} from "../../../components/ui/DataList/style";
import BaseMaster from "../../BaseMaster";
import {withAccountContext} from "../../../contexts/AccountContext";

class PlayedHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tickets: []
        }

    }


    componentDidMount = async () => {
        await this.fetchData();
    }

    fetchData = async () => {
        this.setState({ loading: true });
        AccountService.tickets({
            sort_field: 'draw_date',
            sort_dir: 'asc',
            used: "yes"
        }).then((data) => {
            if(data){
                this.setState({
                    tickets: data
                })
            }
        }).catch((error) => {

        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <MyAccount loading={this.state.loading}>
                <TicketCreatingProvider value={{
                    state: this.state
                }}>
                    {Object.keys(this.state.tickets).length > 0?Object.keys(this.state.tickets).map((ticketDate, index) => {
                        let row = this.state.tickets[ticketDate];
                        return (
                            <TicketGroup>
                                <TicketGroupDate>
                                    <div>{applicationContext.translator("Tickets used for lottery on date ${date}").replaceAll("${date}", toDate(ticketDate, applicationContext.state.locale))}</div>
                                    <BallContainer margin={"0"}>
                                        {row.numbers.map((number) => {
                                            return <Ball fill={true}><span>{number}</span></Ball>
                                        })}
                                        <Plus>+</Plus>
                                        <Ball reserve={true} fill={true}><span>{row.reserve}</span></Ball>
                                    </BallContainer>
                                </TicketGroupDate>
                                <TicketGroupContainer>
                                    {row.tickets.map((ticket, i) => {
                                        return (
                                            <TicketSection>
                                                <Ticket numbers={ticket.numbers}
                                                        selected={row.numbers}
                                                        reserve={ticket['prize_num'] === 6 && row.reserve}
                                                        id={ticket.id}
                                                        prize={applicationContext.translator(ticket.prize)}
                                                        date={toDate(ticket.created_at, applicationContext.state.locale, true, false)}
                                                />
                                            </TicketSection>
                                        )
                                    })}
                                </TicketGroupContainer>
                            </TicketGroup>
                        )
                    }):(
                        <NotFound>
                            <NotFoundWrapper>
                                <img src={"/assets/images/norecordfound.png"} />
                                <p>{applicationContext.translator("No records found.")}</p>
                            </NotFoundWrapper>
                        </NotFound>
                    )}
                </TicketCreatingProvider>
            </MyAccount>
        );
    }
}

export default withAlert()(withApplicationContext(withAccountContext(withAuth(PlayedHistory))));