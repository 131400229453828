import React, {Component} from 'react';
import {Container} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
class NationalIDBox extends Component {

    render() {
        const {borderColor, id, margin} = this.props;

        return (
            <Container borderColor={borderColor} margin={margin}>
                 {id.length === 10 && (id.substr(0, 3).concat("-").concat("****").concat(id.substr(7, 2)).concat("-").concat(id.substr(9, 1)))}
            </Container>
        );
    }
}
NationalIDBox.propTypes = {
    id: PropTypes.string,
    borderColor: PropTypes.string,
    margin: PropTypes.bool
}
export default withApplicationContext(NationalIDBox);