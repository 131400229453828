import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import {Formik} from "formik";
import * as Yup from "yup";
import MyAccount from "../MyAccount";
import {Col, Form, Row} from "react-bootstrap";
import {Text, CopyIcon} from "./style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../../components/ui/Button/Button";
import SuccessMessage from "../../../components/ui/SuccessMessage";
import withAuth from "../../../contexts/withAuth";
import AccountService from "../../../services/AccountService";
import {parseErrorMessage} from "../../../helpers";
import BaseMaster from "../../BaseMaster";
import {withAccountContext} from "../../../contexts/AccountContext";
class Deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: "",
            receipt_hash_code: "",
            wallet: props.applicationContext.state.wallet['address'],
            successfully: false,
            submitting: false
        }

    }


    componentDidMount() {

    }

    handleCopyToClipboard = (e) => {
        const {alert} = this.props;
        alert.success(this.props.applicationContext.translator("The wallet address has been copied."));
    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, alert} = this.props;

        this.setState({ submitting: true });
        try{
            let result = await AccountService.deposit({
                ...values
            });

            this.setState({
                successfully: true,
                submitting: false
            })
        }catch (e){
            this.setState({ submitting: false });
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
            })

        }
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <MyAccount>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state}

                    validationSchema={Yup.object().shape({
                        amount: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .label(applicationContext.translator("Amount"))
                            .min(1, applicationContext.translator("Amount must be greater than or equal to 1")),
                        receipt_hash_code: Yup.string()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .label(applicationContext.translator("Hash code"))
                            .test('len', applicationContext.translator("The hash code must be bigger than 30 character"), (value) => value.toString().length > 30)
                    })}
                    onSubmit={this.handleSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                            <Form.Group as={Row} >
                                <Col md={7}>
                                    {this.state.successfully?(
                                        <SuccessMessage
                                            message={"Your deposit will be transferred soon in to your account"}
                                            subText={"Please wait until confirmation and do not register again with this transaction code"}
                                            icon={true}
                                        />
                                    ):(
                                        <React.Fragment>
                                            <Text>{applicationContext.translator("Please transfer your desire amount to this tether (trc20)  ussd  wallet address")}</Text>
                                            <InputBox type="text"
                                                      name={"wallet"}
                                                      onChange={handleChange}
                                                      value={values.wallet}
                                                      readonly={true}
                                                      errors={errors}
                                                      touched={touched}
                                                      button={(e) => {}}
                                                      buttonAlign={"left"}
                                                      buttonLabel={
                                                          <CopyToClipboard text={this.state.wallet} onCopy={this.handleCopyToClipboard}>
                                                              <CopyIcon>
                                                                  <FontAwesomeIcon icon={"copy"} /> {applicationContext.translator("Copy")}
                                                              </CopyIcon>
                                                          </CopyToClipboard>
                                                      }
                                            />
                                            <Text>{applicationContext.translator("Once you have transferred the amount to the myroulette tether account, enter the same amount together with the hash code and click OK. After approval, from our support (which takes approx 15/30 minutes) refresh the page and the total amount of your deposit will show in the account balance.")}</Text>
                                            <InputBox type="text"
                                                      name={"amount"}
                                                      onChange={handleChange}
                                                      label={applicationContext.translator("Amount")}
                                                      value={values.amount}
                                                      save={"off"}
                                                      onlyNumber={true}
                                                      line={true}
                                                      maxLength={5}
                                                      errors={errors}
                                                      touched={touched}
                                                      postfix={"USD"}
                                            />
                                            <InputBox type="text"
                                                      name={"receipt_hash_code"}
                                                      onChange={handleChange}
                                                      label={applicationContext.translator("Hash code")}
                                                      value={values.receipt_hash_code}
                                                      save={"off"}
                                                      line={true}
                                                      errors={errors}
                                                      touched={touched}
                                            />
                                            <Form.Group className={"text-right"}>
                                                <Button color={"black-primary"} fullWidth={true} size={3} submitting={this.state.submitting} radius={7} type={"submit"}>{applicationContext.translator("OK")}</Button>
                                            </Form.Group>
                                        </React.Fragment>
                                    )}
                                </Col>
                                <Col md={5} className={"text-center"}>
                                    <div className={"text-center mb-3"}>
                                        <img src={applicationContext.state.wallet['image']} width={"80%"}/>
                                    </div>
                                </Col>



                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </MyAccount>
        );
    }
}

export default withAlert()(withApplicationContext(withAccountContext(withAuth(Deposit))));