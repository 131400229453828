import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {Title, TitleBar} from "./style.jsx";
import {Col, Container, Row} from "react-bootstrap";
import SubHeaderBar from "../../components/ui/SubHeaderBar";
import TicketCreating from "./TicketCreating";
import Checkout from "./Checkout";
import BaseMaster from "../BaseMaster";
class BuyTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <SubHeaderBar />
                <TicketCreating />

            </React.Fragment>

        );
    }
}

export default withApplicationContext(BuyTicket);