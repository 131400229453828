import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {
    SectionContainer,
    DrawResultTitle, ResultContainer
} from "./style.jsx";
import {Container, Row, Col, NavLink} from 'react-bootstrap';
import HorizontalBalls from "../../components/ui/Ball/HorizontalBalls";
import JackpotPrice from "../../components/ui/JackpotPrice/JackpotPrice";
import NationalIDBox from "../../components/ui/NationalIDBox";
import Winner from "../../components/ui/Winner";
import PersianDate from "../../components/ui/PersianDate";

class DrawResults extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer>
                <Container>
                    {/*<Row>*/}
                    {/*    <Col md={5}>*/}
                    {/*        */}
                    {/*    </Col>*/}
                    {/*    <Col md={2}></Col>*/}
                    {/*    <Col md={5}>*/}
                    {/*        <DrawResultTitle>*/}
                    {/*            <span>{applicationContext.translator("National ID Winner")}</span>*/}
                    {/*            <PersianDate date={"2023-03-24"}/>*/}
                    {/*        </DrawResultTitle>*/}
                    {/*        <NationalIDBox id={"0123456789"} margin={true} />*/}
                    {/*        <Winner name={"Mike Tayson"} margin={true} />*/}

                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <ResultContainer>
                        <DrawResultTitle>
                            <span>{applicationContext.translator("Draw Result")}</span>
                            {applicationContext.state.lastLottery && <PersianDate date={applicationContext.state.lastLottery.date}/>}
                        </DrawResultTitle>
                        <HorizontalBalls numbers={applicationContext.state.lastLottery?applicationContext.state.lastLottery.won_number:[0,0,0,0,0,0]}
                                         reserve={applicationContext.state.lastLottery?applicationContext.state.lastLottery['reserve_number']:"0"} />
                        <JackpotPrice size={1} margin={true}/>
                    </ResultContainer>
                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(DrawResults);