import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {
    CheckoutContainer, CheckoutWrapper, CheckoutTitle, CheckoutSubTitle, MultiDrawOption, Line,
    CartContainer, CartItem, CartItemLabel, CartItemPrice
} from "./style.jsx";
import {Col, Container, Form, Row} from "react-bootstrap";
import {withTicketCreatingContext} from "../../contexts/TicketCreatingContext";
import {deleteNullItemInArray, getNextDayOfWeek, getNextFriday, parseErrorMessage, utcTime} from "../../helpers";
import moment from "jalali-moment";
import Button from "../../components/ui/Button";
import LoginForm from "../Login/Forms/LoginForm";
import AccountService from "../../services/AccountService";
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import {withAccountContext} from "../../contexts/AccountContext";
class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            checkout: false
        }

    }


    onCheckout = async (e) => {
        const {applicationContext, accountContext} = this.props;
        if(!accountContext.state.loggedIn)
            return applicationContext.onOpenPopup("Log In", <LoginForm onCallback={this.handleCheckout} />);

        await this.handleCheckout();
    }

    handleCheckout = async () => {
        const {applicationContext, accountContext, alert, ticketCreatingContext} = this.props;
        this.setState({ submitting: true })
        applicationContext.onClosePopup();
        try{
            let numbers = [];
            ticketCreatingContext.state.tickets.forEach((ticket) => {
                numbers.push(deleteNullItemInArray(ticket.numbers));
            })
            let tickets = AccountService.checkout({
                numbers,
                draw: ticketCreatingContext.state.draw
            }).catch((e) => {
                let error = e.response.data;
                if(error.hasOwnProperty('locked') && error.locked){
                    ticketCreatingContext.handleLockSelling(error.locked, error['lock_time'][0], error['lock_time'][1], error.message);
                }else{
                    //
                    if(error.hasOwnProperty('errors') && error.errors){
                        if(error.errors.hasOwnProperty('price') && error.errors['price'].includes('Your account balance is not enough'))
                            applicationContext.playSound("chargeCredit")

                        let numberErrors = false;
                        Object.keys(error.errors).map((t) => {
                            let indexes = t.split(".");
                            if(indexes.length === 2 && indexes[0] === "numbers"){
                                let tickets = ticketCreatingContext.state.tickets;
                                tickets[parseInt(indexes[1])].error = applicationContext.translator(error.errors[t][0].replaceAll(t, "ticket"));
                                ticketCreatingContext.handleUpdateTickets(tickets);
                                numberErrors = true;
                            }

                        });
                        Object.keys(error.errors).map((t) => {
                            if(!numberErrors && !t.startsWith('numbers')){
                                alert.error(applicationContext.translator(error.errors[t][0]))
                            }
                        });
                    }else{
                        alert.error(applicationContext.translator(error.message))
                    }
                }


            }).then((data) => {
                if(data){
                    AccountService.getUserBoard().then((userInfo) => {
                        accountContext.updateUserInfo(userInfo, () => {
                            ticketCreatingContext.handleUpdateSuccess(true);
                        })
                    })
                }
            }).finally(() => {
                this.setState({ submitting: false });
            })

        }catch (err){
            this.setState({ submitting: false });
            console.log(err.response.data);
            //alert.error(applicationContext.translator(parseErrorMessage(err)));
        }

    }


    render() {
        const {applicationContext, ticketCreatingContext} = this.props;
        return (
            <CheckoutContainer>
                <CheckoutWrapper>
                    <CheckoutTitle>{applicationContext.translator("Multi Draw")}</CheckoutTitle>
                    <CheckoutSubTitle dangerouslySetInnerHTML={{__html: applicationContext.translator("Use the same tickets from multi draws, this way, you won't miss participating.")}} />

                    <MultiDrawOption
                        type={"radio"}
                        id={`multi-draw-count-1`}
                        name="multiDraw"
                        value={1}
                        defaultChecked={ticketCreatingContext.state.draw === 1}
                        onChange={(e) => ticketCreatingContext.handleUpdateDraw(e.target.value)}
                        label={applicationContext.translator("One draw only")}
                    />
                    <MultiDrawOption
                        type={"radio"}
                        id={`multi-draw-count-2`}
                        name="multiDraw"
                        value={4}
                        defaultChecked={ticketCreatingContext.state.draw === 4}
                        onChange={(e) => ticketCreatingContext.handleUpdateDraw(e.target.value)}
                        label={applicationContext.translator("4 draws")}
                    />
                    <MultiDrawOption
                        type={"radio"}
                        id={`multi-draw-count-3`}
                        name="multiDraw"
                        value={8}
                        defaultChecked={ticketCreatingContext.state.draw === 8}
                        onChange={(e) => ticketCreatingContext.handleUpdateDraw(e.target.value)}
                        label={applicationContext.translator("8 draws")}
                    />
                    <MultiDrawOption
                        type={"radio"}
                        id={`multi-draw-count-4`}
                        name="multiDraw"
                        value={16}
                        defaultChecked={ticketCreatingContext.state.draw === 16}
                        onChange={(e) => ticketCreatingContext.handleUpdateDraw(e.target.value)}
                        label={applicationContext.translator("16 draws")}
                    />
                    <MultiDrawOption
                        type={"radio"}
                        id={`multi-draw-count-5`}
                        name="multiDraw"
                        value={24}
                        defaultChecked={ticketCreatingContext.state.draw === 24}
                        onChange={(e) => ticketCreatingContext.handleUpdateDraw(e.target.value)}
                        label={applicationContext.translator("24 draws")}
                    />
                    <Line />
                    <CheckoutTitle>{applicationContext.translator("Order Summary")}</CheckoutTitle>
                    <CartContainer>
                        <CartItem>
                            <CartItemLabel>
                                <b>{ticketCreatingContext.state.tickets.length} {applicationContext.translator("Ticket")}</b>
                                <small>${applicationContext.state.ticketPrice} برای هر بلیط</small>
                            </CartItemLabel>
                            <CartItemPrice>
                                ${(applicationContext.state.ticketPrice * ticketCreatingContext.state.tickets.length) },-
                            </CartItemPrice>
                        </CartItem>
                        <CartItem>
                            <CartItemLabel>
                                <b>{ticketCreatingContext.state.draw} {applicationContext.translator("Lottery")}</b>
                                {parseInt(ticketCreatingContext.state.draw) === 1 && (<small>{applicationContext.translator("for")} {moment(utcTime(getNextFriday(ticketCreatingContext.state.draw, new Date(ticketCreatingContext.state.server_time))), 'YYYY/MM/DD').locale("fa").format('dddd, D MMMM YYYY')}</small>)}
                                {ticketCreatingContext.state.draw > 1 && (
                                    <React.Fragment>
                                        <small>{applicationContext.translator("from")} {moment(utcTime(getNextFriday(1, new Date(ticketCreatingContext.state.server_time))), 'YYYY/MM/DD').locale("fa").format('dddd, D MMMM YYYY')}</small><br/>
                                         <small>{applicationContext.translator("to")} {moment(utcTime(getNextFriday(ticketCreatingContext.state.draw, new Date(ticketCreatingContext.state.server_time))), 'YYYY/MM/DD').locale("fa").format('dddd, D MMMM YYYY')}</small>
                                    </React.Fragment>

                                )}
                            </CartItemLabel>
                            <CartItemPrice>
                                x{ticketCreatingContext.state.draw}
                            </CartItemPrice>
                        </CartItem>
                        <Line />
                        <CartItem>
                            <CartItemLabel>
                                <b>{applicationContext.translator("Total")}</b>
                            </CartItemLabel>
                            <CartItemPrice>
                                ${(applicationContext.state.ticketPrice * ticketCreatingContext.state.tickets.length) * ticketCreatingContext.state.draw},-
                            </CartItemPrice>
                        </CartItem>
                    </CartContainer>
                    <Button color={"black"} submitting={this.state.submitting} disabled={ticketCreatingContext.state.tickets.length === 0 || ticketCreatingContext.state.lock} fullWidth={true} margin={"1em 0 0 0"} onClick={this.onCheckout} size={2}>{applicationContext.translator("Checkout")}</Button>
                </CheckoutWrapper>

            </CheckoutContainer>

        );
    }
}

export default withAlert()(withApplicationContext(withTicketCreatingContext(withRouter(withAccountContext(Checkout)))));