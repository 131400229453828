import api from "./api";
import TokenService from "./TokenService";
import {queryStringSerialize} from "../helpers";
import axios from "axios";
const getUserBoard = () => {
    return api.get("/account/profile/me").then((response) => {
        TokenService.setUser(response.data);
        return TokenService.getUser();
    });
};
const deposit = (data) => {
    return api
        .put("/account/deposits/create", data)
        .then((response) => {
            return response.data;
        });
};
const deposits = (data) => {
    return api
        .get("/account/deposits?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const checkout = (data) => {
    return api
        .put("/account/tickets/create", data)
        .then((response) => {
            return response.data;
        });
};

const tickets = (data) => {
    return api
        .get("/account/tickets/groupByDate?" + queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const updateProfile = (data) => {
    return api
        .post("/account/profile/update", data)
        .then((response) => {
            return response.data;
        });
};

const updateProfilePassword = (data) => {
    return api
        .post("/account/profile/password", data)
        .then((response) => {
            return response.data;
        });
};

const register = (url, data) => {
    let config = {
        auth: {
            username: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_ID,
            password: process.env.REACT_APP_BACK_END_WEBSERVICE_API_CLIENT_SECRET
        }
    };
    return axios.create({
        baseURL: process.env.REACT_APP_BACK_END_WEBSERVICE_API,
        headers: {
            "Content-Type": "application/json",
        },
    }).post(url, data, config)
        .then((response) => {
            return response.data;
        });
};

const AccountService = {
    getUserBoard,
    updateProfile,
    updateProfilePassword,
    deposits,
    deposit,
    checkout,
    tickets,
    register
};

export default AccountService;