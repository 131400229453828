import React, {Component} from 'react';
import {Container, WinnerIcon, WinnerInfo, WinnerName} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
class Winner extends Component {

    render() {
        const {name, margin, applicationContext} = this.props;

        return (
            <Container margin={margin}>
                <WinnerIcon image={"/assets/images/winner.png"} />
                <WinnerInfo>
                    <WinnerName>{name}</WinnerName>
                    <span>{applicationContext.translator("Has Won")}</span>
                    <span>{applicationContext.translator("100,000,000 Toman")}</span>
                </WinnerInfo>
            </Container>
        );
    }
}
Winner.propTypes = {
    name: PropTypes.string,
    margin: PropTypes.bool
}
export default withApplicationContext(Winner);