import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {
    SectionContainer,
    PrizesContainer,
    CenterCol,
    PrizeTitle,
    PrizeList, PrizeListItem,
    PrizeImage
} from "./style.jsx";
import {Container, Row, Col, NavLink} from 'react-bootstrap';
import HorizontalBalls from "../../components/ui/Ball/HorizontalBalls";
import JackpotPrice from "../../components/ui/JackpotPrice/JackpotPrice";
import NationalIDBox from "../../components/ui/NationalIDBox";
import Winner from "../../components/ui/Winner";
import PersianDate from "../../components/ui/PersianDate";

class Prizes extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer>
                <Container>
                    <PrizesContainer>
                        <Row>
                            <Col md={8}>
                                <PrizeTitle>{applicationContext.translator("Prizes")}</PrizeTitle>
                                <PrizeList>
                                    <PrizeListItem winner={true}>
                                        <span>{applicationContext.translator("6 Matched Numbers")}</span>
                                        <span>{applicationContext.translator("1,000,000,000 Toman")}</span>
                                    </PrizeListItem>
                                    <PrizeListItem>
                                        <span>{applicationContext.translator("5+ Reserved")}</span>
                                        <span>{applicationContext.translator("500,000,000 Toman")}</span>
                                    </PrizeListItem>
                                    <PrizeListItem>
                                        <span>{applicationContext.translator("5 Matched Numbers")}</span>
                                        <span>{applicationContext.translator("50,000,000 Toman")}</span>
                                    </PrizeListItem>
                                    <PrizeListItem>
                                        <span>{applicationContext.translator("4 Matched Numbers")}</span>
                                        <span>{applicationContext.translator("20 Play Free")}</span>
                                    </PrizeListItem>
                                    <PrizeListItem>
                                        <span>{applicationContext.translator("3 Matched Numbers")}</span>
                                        <span>{applicationContext.translator("10 Play Free")}</span>
                                    </PrizeListItem>
                                </PrizeList>

                            </Col>
                            <CenterCol md={4}>
                                <PrizeImage src={"/assets/images/loto-prizes.png"}/>
                            </CenterCol>
                        </Row>
                    </PrizesContainer>
                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(Prizes);