import React, {Component} from 'react';
import SubHeaderBar from "../../components/ui/SubHeaderBar/SubHeaderBar";
import {Container} from "react-bootstrap";
import {LoginContainer} from "./style";

class Registration extends Component {
    render() {
        const {children} = this.props;
        return (
            <React.Fragment>
                <SubHeaderBar />
                <Container>
                    <LoginContainer>
                        {children}
                    </LoginContainer>
                </Container>
            </React.Fragment>
        );
    }
}

export default Registration;