import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import MyAccount from "../MyAccount";
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import {
    dayRange,
    msisdn,
    parseErrorMessage,
    passwordCheck,
    toSearchDataList,
    utcTime,
    yearRange, zeroPad
} from "../../../helpers";
import PersianDatePicker from "../../../components/ui/PersianDatePicker/PersianDatePicker";
import SearchDropdown from "../../../components/ui/SearchDropdown/SearchDropdown";
import Button from "../../../components/ui/Button/Button";
import {Formik} from "formik";
import withRouter from "../../../contexts/withRouter";
import {ROUTE_ACCOUNT_PROFILE_EMAIL_UPDATE, ROUTE_ACCOUNT_PROFILE_MOBILE_UPDATE} from "../../../routes/routes";
import withAuth from "../../../contexts/withAuth";
import AccountService from "../../../services/AccountService";
import {ProfilePasswordHelp} from "./style"
import BaseMaster from "../../BaseMaster";
import {withAccountContext} from "../../../contexts/AccountContext";
import {InputError, Label} from "../../../components/ui/InputBox/style";
import {Flex} from "../../Register/style";
import Dropdown from "../../../components/ui/Dropdown/Dropdown";
import moment from "jalali-moment";
class Profile extends Component {
    constructor(props) {
        super(props);
        const {applicationContext, accountContext} = props;
        let date = accountContext.state.userInfo['date_of_birth'];
        let birthdate = date.split("-");
        if(applicationContext.state.locale === "fa"){
            date = moment(utcTime(date).format('YYYY-MM-DD'), 'YYYY-MM-DD').locale("fa").format("YYYY/MM/DD")
            birthdate = date.split("/");
        }
        this.state = {
            email: props.accountContext.state.userInfo['email'],
            mobile: props.accountContext.state.userInfo['msisdn'],
            national_id: props.accountContext.state.userInfo['national_id'],
            first_name: props.accountContext.state.userInfo['first_name'],
            last_name: props.accountContext.state.userInfo['last_name'],
            date_of_birth: new Date(props.accountContext.state.userInfo['date_of_birth']),
            state_id: props.accountContext.state.userInfo['state_id']?props.accountContext.state.userInfo['state_id']['id']:null,
            birthdate,
            year_of_birth: birthdate[0],
            month_of_birth: birthdate[1],
            day_of_birth: birthdate[2],
            password: "",
            password_confirmation: "",
            submittingProfile: false,
            submittingPassword: false,
            passwordHelp: null
        }

    }


    componentDidMount() {

    }

    handleUpdateProfileSubmit = (values, actions) => {
        const {applicationContext, alert} = this.props;
        this.setState({ submittingProfile: true });
        let date_of_birth = values.year_of_birth+"-"+zeroPad(values.month_of_birth, 2)+"-"+zeroPad(values.day_of_birth, 2);
        if(applicationContext.state.locale === "fa"){
            date_of_birth = values.year_of_birth+"/"+zeroPad(values.month_of_birth, 2)+"/"+zeroPad(values.day_of_birth, 2);
            let date = moment.from(date_of_birth, 'fa', 'YYYY/MM/DD');
            date_of_birth = (date['_a'][0].toString()+"-"+zeroPad((date['_a'][1] + 1).toString(), 2)+"-"+zeroPad(date['_a'][2].toString(), 2))
        }
        AccountService.updateProfile({
            ...values,
            date_of_birth
        }).then((data) => {
            if(data)
                alert.success(applicationContext.translator("You account information updated successfully"));
        }).catch((e) => {
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
            })
        }).finally(() => {
            this.setState({ submittingProfile: false });
        })
    }

    handleUpdatePasswordSubmit = (values, actions) => {
        const {applicationContext, alert} = this.props;
        this.setState({ submittingPassword: true });
        AccountService.updateProfilePassword(values).then((data) => {
            if(data) {
                this.setState({
                    password: "",
                    password_confirmation: ""
                })
                alert.success(applicationContext.translator("You account password updated successfully"));
            }

        }).catch((e) => {
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
            })
        }).finally(() => {
            this.setState({submittingPassword: false});
        });
    }
    checkProfileChange = (values) => {
        const {applicationContext, accountContext} = this.props;
        let profile = {
            email: accountContext.state.userInfo['email'],
            mobile: accountContext.state.userInfo['msisdn'],
            national_id: accountContext.state.userInfo['national_id'],
            first_name: accountContext.state.userInfo['first_name'],
            last_name: accountContext.state.userInfo['last_name'],
            year_of_birth: this.state.birthdate[0],
            month_of_birth: this.state.birthdate[1],
            day_of_birth: this.state.birthdate[2],
            state_id: accountContext.state.userInfo['state_id']?accountContext.state.userInfo['state_id']['id']:null
        }
        let changed = false;
        Object.keys(profile).forEach((key) => {
            if(profile[key] !== values[key]){
                changed = true;
            }

        })
        return !changed;
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <MyAccount>
                <Formik
                    innerRef={(ref) => this.refUpdateProfileForm = ref}
                    initialValues={{
                        email: this.state.email,
                        mobile: this.state.mobile,
                        national_id: this.state.national_id,
                        first_name: this.state.first_name,
                        last_name: this.state.last_name,
                        year_of_birth: this.state.year_of_birth,
                        month_of_birth: this.state.month_of_birth,
                        day_of_birth: this.state.day_of_birth,
                        state_id: this.state.state_id
                    }}
                    validationSchema={Yup.object().shape({
                        national_id: Yup.string()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .label(applicationContext.translator("National id"))
                            .test('len', applicationContext.translator("${path} must be exactly 10 characters"), val => val.toString().length === 10),
                        first_name: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("First name")),
                        last_name: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Last name")),
                        year_of_birth: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Year of birth")),
                        month_of_birth: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Year of birth")),
                        day_of_birth: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Year of birth")),
                        date_of_birth: Yup.string().test('birth_date', applicationContext.translator("You must select your date of birth"), (value, context) => {
                            return (context.parent.year_of_birth && context.parent.month_of_birth && context.parent.day_of_birth);
                        }),
                        state_id: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("State"))
                    })}
                    onSubmit={this.handleUpdateProfileSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                <Row>
                                    <Col md={6}>
                                        <InputBox type="text"
                                                  name={"email"}
                                                  onChange={handleChange}
                                                  value={values.email}
                                                  save={"off"}
                                                  label={applicationContext.translator("E-Mail Address")}
                                                  disabled={true}
                                                  errors={errors}
                                                  touched={touched}
                                                  button={(e) => {
                                                      const {navigate} = this.props;
                                                      navigate(ROUTE_ACCOUNT_PROFILE_EMAIL_UPDATE);
                                                  }}
                                                  buttonLabel={applicationContext.translator("Change E-Mail")}
                                                  buttonAlign={"left"}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputBox type="text"
                                                  name={"mobile"}
                                                  onChange={handleChange}
                                                  label={applicationContext.translator("Mobile number")}
                                                  value={msisdn(values.mobile)}
                                                  save={"off"}
                                                  disabled={true}
                                                  errors={errors}
                                                  touched={touched}
                                                  button={(e) => {
                                                      const {navigate} = this.props;
                                                      navigate(ROUTE_ACCOUNT_PROFILE_MOBILE_UPDATE);
                                                  }}
                                                  buttonLabel={applicationContext.translator("Change Mobile")}
                                                  buttonAlign={"left"}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputBox type="text"
                                                  name={"first_name"}
                                                  onChange={handleChange}
                                                  label={applicationContext.translator("First name")}
                                                  value={values.first_name}
                                                  save={"off"}
                                                  errors={errors}
                                                  touched={touched}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputBox type="text"
                                                  name={"last_name"}
                                                  onChange={handleChange}
                                                  label={applicationContext.translator("Last name")}
                                                  value={values.last_name}
                                                  save={"off"}
                                                  errors={errors}
                                                  touched={touched}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputBox type="text"
                                                  name={"national_id"}
                                                  onChange={handleChange}
                                                  label={applicationContext.translator("National id")}
                                                  value={values.national_id}
                                                  save={"off"}
                                                  onlyNumber={true}
                                                  maxLength={10}
                                                  errors={errors}
                                                  touched={touched}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SearchDropdown
                                            name={"state_id"}
                                            onChange={(e) => setFieldValue('state_id', e.selectedKey[0]) }
                                            data={toSearchDataList(applicationContext.translator, applicationContext.state.states, ['name'], values.state_id)}
                                            label={applicationContext.translator("State")}
                                            value={values.state_id}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label>{applicationContext.translator("Date of birth")}</Label>
                                        <Flex>
                                            <Dropdown
                                                name={"year_of_birth"}
                                                onChange={handleChange}
                                                value={values.year_of_birth}
                                                invalid={touched.year_of_birth && errors.year_of_birth}
                                                noInvalidIcon={true}
                                                boxClassName={"dropdown year-dropdown"}
                                                padding={"0 .4em 0 0"}
                                                margin={"0"}
                                            >
                                                <option value={""}>{applicationContext.translator("Year of birth")}</option>
                                                {yearRange(new Date().getFullYear() - 90, new Date().getFullYear() - 18).map((year, index) => {
                                                    return <option key={index} value={year} >{year}</option>
                                                })}
                                            </Dropdown>
                                            <Dropdown
                                                name={"month_of_birth"}
                                                onChange={handleChange}
                                                value={values.month_of_birth}
                                                invalid={touched.month_of_birth && errors.month_of_birth}
                                                noInvalidIcon={true}
                                                boxClassName={"dropdown month-dropdown"}
                                                padding={"0 .2em"}
                                                margin={"0"}
                                            >
                                                <option value={""}>{applicationContext.translator("Month of birth")}</option>
                                                <option value={1} >{applicationContext.translator("January")}</option>
                                                <option value={2} >{applicationContext.translator("February")}</option>
                                                <option value={3} >{applicationContext.translator("March")}</option>
                                                <option value={4} >{applicationContext.translator("April")}</option>
                                                <option value={5} >{applicationContext.translator("May")}</option>
                                                <option value={6} >{applicationContext.translator("June")}</option>
                                                <option value={7} >{applicationContext.translator("July")}</option>
                                                <option value={8} >{applicationContext.translator("August")}</option>
                                                <option value={9} >{applicationContext.translator("September")}</option>
                                                <option value={10} >{applicationContext.translator("October")}</option>
                                                <option value={11} >{applicationContext.translator("November")}</option>
                                                <option value={12} >{applicationContext.translator("December")}</option>
                                            </Dropdown>
                                            <Dropdown
                                                name={"day_of_birth"}
                                                onChange={handleChange}
                                                value={values.day_of_birth}
                                                invalid={touched.day_of_birth && errors.day_of_birth}
                                                noInvalidIcon={true}
                                                boxClassName={"dropdown day-dropdown"}
                                                padding={"0 0 0 .4em"}
                                                margin={"0"}
                                            >
                                                <option value={""}>{applicationContext.translator("Day of birth")}</option>
                                                {dayRange(values.month_of_birth, applicationContext.state.locale).map((day, index) => {
                                                    return <option key={index} value={day} >{day}</option>
                                                })}
                                            </Dropdown>
                                        </Flex>
                                        {errors && errors['date_of_birth'] && <InputError hidden={errors['date_of_birth'] === undefined && true}>{errors['date_of_birth']}</InputError>}


                                    </Col>

                                </Row>
                                <Form.Group className={"text-right mt-3"}>
                                    <Button color={"primary"} size={3} submitting={this.state.submittingProfile} disabled={this.checkProfileChange(values)} radius={10} type={"submit"}>{applicationContext.translator("Update Changes")}</Button>
                                </Form.Group>

                            </Form>
                        )}
                </Formik>

                <hr/>

                <Formik
                    innerRef={(ref) => this.refUpdatePasswordForm = ref}
                    initialValues={{
                        email: this.state.email,
                        password: this.state.password,
                        password_confirmation: this.state.password_confirmation
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
                        password: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                            .test('password', applicationContext.translator("The password must contain at least 8 english characters and include upper and lower case letters, numbers and a symbol"), (value) => {
                                let check = passwordCheck(value);
                                return (check.message === "Strong")
                            })
                            .label(applicationContext.translator("Password")),
                        password_confirmation: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                            .oneOf([Yup.ref('password'), null], applicationContext.translator('Passwords must match'))
                            .label(applicationContext.translator("Password Confirm"))
                    })}
                    onSubmit={this.handleUpdatePasswordSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                <Row>
                                    <Col md={6}>
                                        <InputBox type="text"
                                                  name={"email"}
                                                  onChange={handleChange}
                                                  value={values.email}
                                                  save={"off"}
                                                  label={applicationContext.translator("E-Mail Address")}
                                                  disabled={true}
                                                  errors={errors}
                                                  touched={touched}
                                        />
                                        <InputBox type="password"
                                                  name={"password"}
                                                  onChange={handleChange}
                                                  label={applicationContext.translator("Password")}
                                                  value={values.Password}
                                                  save={"off"}
                                                  errors={errors}
                                                  touched={touched}
                                                  strength={true}
                                                  onCallbackStrength={(passwordHelp) => {
                                                      this.setState({
                                                          passwordHelp
                                                      })
                                                  }}
                                        />
                                        <InputBox type="password"
                                                  name={"password_confirmation"}
                                                  onChange={handleChange}
                                                  label={applicationContext.translator("Password Confirm")}
                                                  value={values.password_confirmation}
                                                  save={"off"}
                                                  errors={errors}
                                                  touched={touched}
                                        />

                                        <Form.Group className={"text-right"}>
                                            <Button color={"primary"}  size={3} submitting={this.state.submittingPassword} radius={10} type={"submit"}>{applicationContext.translator("Update Password")}</Button>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <ProfilePasswordHelp>
                                            {this.state.passwordHelp}
                                        </ProfilePasswordHelp>

                                    </Col>
                                </Row>
                            </Form>
                        )}
                </Formik>
            </MyAccount>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(withAuth(Profile)))));