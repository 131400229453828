import styled from "styled-components";
import {Button as RButton, Form, FormControl} from "react-bootstrap"
export const Container = styled.div`
  border: 1px solid var(--color-secondary);
  border-radius: 13px;
  padding: 1.6em 2em;
  min-height: 255px;
  ${(props) => props.showResult && `
        padding: 1.6em 6em;
        & h5{
            font-size: 1.2em;
            margin-bottom: 0em;
        }
  `}
`;
export const Title = styled.h5`
  color: var(--color-secondary);
  line-height: 1.3em;
  font-weight: 700;
  font-size: 1.8em;
  margin-bottom: 1.5em;
`;
export const InputBox = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5em 0 0.5em 0;
  background: var(--color-primary);
  color: var(--color-secondary-on2);
  border-radius: 20px;
  height: 40px;
  position: relative;
`;
export const Label = styled.span`
  
  width: 130px;
  min-width: 130px;
  padding: 0.4em 1em;
  
`;
export const Input = styled(FormControl)`
  width: calc(100% - (130px + 2px));
  border-radius: 20px;
  height: 36px;
  border: 0;
  text-align: center;
  direction: ltr;
  font-size: 1.3em;
  font-weight: 500;
  &:focus{
    border-color: rgb(241 204 1);
    box-shadow: 0 0 0 0.25rem rgb(241 204 1 / 25%);
  }
  ${(props) => props.isInvalid && `
        border:1px solid #dc3545;
  `}
  
`;
export const Button = styled(RButton)`
  width: calc(100% - 130px);
  margin-right: 130px;
  margin-bottom: 0.5em;
  margin-top: .5em;
  border:2px solid  var(--color-primary);
  color: var(--color-secondary-on2);
  background: #fff;
  height: 40px;
  border-radius: 20px;
  &:hover, &:focus{
    border:2px solid  var(--color-primary);
    color: var(--color-secondary-on2);
    background: var(--color-primary);
  }
  &:focus{
    box-shadow: 0 0 0 0.25rem rgb(241 204 1 / 25%) !important;
  }
  &:active {
    border:2px solid  var(--color-secondary) !important;
    color: #fff !important;;
    background: var(--color-secondary) !important;;
  }

  &:disabled{
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    opacity: var(--bs-btn-disabled-opacity);
  }
`

export const InputError = styled(Form.Text)`
    color: #dc3545;
    margin: 0em 0.3em;
      position: absolute;
      top: -23px;
      right: 7px;
    ${(props) => props.hidden && `
        display: none;
    `}
    

`