import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {Spinner} from "react-bootstrap";

export const Table = styled.div`
  width: 100%;
  display: table;
  border-collapse: separate;
  & .table_row > .table_small > .table_cell:first-child {
    display: none;
    background: var(--color-table-header-background);
    color: var(--color-table-header-text);
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 700px) {
    display: block;
    & .table_row:nth-child(2n+3) {
      background: none;
    }
    & .table_row > .table_small:first-child > .table_cell:first-child {
      ${(props) => props.dir === "rtl"?`border-top-right-radius:var(--color-table-border-radius);`:`border-top-left-radius:var(--color-table-border-radius);`}
    }
    & .table_row > .table_small:last-child > .table_cell:first-child {
      ${(props) => props.dir === "rtl"?`border-bottom-right-radius:var(--color-table-border-radius);`:`border-bottom-left-radius:var(--color-table-border-radius);`}
    }
    & .theader {
      display: none;
    }
    & .table_row > .table_small > .table_cell:nth-child(odd) {
      display: table-cell;
      width: 40%;
    }
    & .table_cell {
      display: table-cell;
      border-top: 1px solid var(--color-table-row-border);
      border-left: 1px solid var(--color-table-row-border);
    }
    & .table_row > .table_small:first-child > .table_cell:last-child {
      ${(props) => props.dir === "rtl"?`border-top-left-radius:var(--color-table-border-radius);`:`border-top-right-radius:var(--color-table-border-radius);`}
      
    }
    & .table_row > .table_small:last-child > .table_cell:last-child{
      border-bottom: 1px solid var(--color-table-row-border);
      ${(props) => props.dir === "rtl"?`border-bottom-left-radius:var(--color-table-border-radius);`:`border-bottom-right-radius:var(--color-table-border-radius);`}
      
    }
    & .table_row {
      display: table;
      width: 100%;
      border-collapse: separate;
      padding-bottom: 20px;
      margin: 5% auto 0;
      text-align: center;
    }
    & .table_small {
      display: table-row;
    }
  }
  
`;
export const TableSmall = styled.div`
  display: table-cell;
`
export const HeaderRow = styled.div`
  display: table-row;
  & .table_header{
    border: 1px solid var(--color-table-header-border);  
  }
  
`
export const FooterRow = styled.div`
  display: flex;
  border-left: 1px solid var(--color-table-header-border);
  border-right: 1px solid var(--color-table-header-border);
  border-bottom: 1px solid var(--color-table-header-border);
  width: 100%;
  border-bottom-left-radius:var(--color-table-border-radius);
  border-bottom-right-radius:var(--color-table-border-radius);
  background: var(--color-table-header-background);
  color: var(--color-table-header-text);
  padding: 1em;
  font-size: .9em;
  & .footer-col{
    margin: 0 .5em;
    &:first-child{
      ${(props) => props.dir === "rtl"?`margin-right: 0;`:`margin-left: 0;`}
    }
    &:last-child{
      ${(props) => props.dir === "rtl"?`margin-left: 0;`:`margin-right: 0;`}
    }
  }
`
export const FooterCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & b{
    margin: 0 .7em;
  }
  & div{
    background: var(--color-table-row-odd-background);
    padding: .3em .5em;
    border-radius: 4px;
  }
`
export const Row = styled.div`
  display: table-row;
  & a{
    color: var(--color-primary);
    text-decoration: underline;
    transition: all ease-in-out .3s;
    &:hover{
      color: var(--color-text);
    }
  }
  @media screen and (min-width: 701px) {
    & .table_small:first-child{
      ${(props) => props.dir === "rtl"?`border-right: 1px solid var(--color-table-row-border);`:`border-left: 1px solid var(--color-table-row-border);`}
      
    }
    & .table_small:last-child{
      ${(props) => props.dir === "rtl"?`border-left: 1px solid var(--color-table-row-border);`:`border-right: 1px solid var(--color-table-row-border);`}
    }
    & > .table_small{
      border-bottom: 1px solid var(--color-table-row-border);
    }
    
    &:hover{
      background: var(--color-table-row-hover-background);
      transition: all ease-in-out .3s;
    }
    &:nth-child(odd){
      background: var(--color-table-row-odd-background);
    }
    &:nth-child(odd):hover{
      background: var(--color-table-row-hover-background);
      transition: all ease-in-out .3s;
    }
    &:last-child > .table_small:first-child > .table_cell:last-child{
      ${(props) => props.dir === "rtl"?`border-bottom-right-radius:var(--color-table-border-radius);`:`border-bottom-left-radius:var(--color-table-border-radius);`}
    }
    &:last-child > .table_small:last-child > .table_cell:last-child{
      ${(props) => props.dir === "rtl"?`border-bottom-left-radius:var(--color-table-border-radius);`:`border-bottom-right-radius:var(--color-table-border-radius);`}
    }
  }
  
  
`
export const Col = styled.div`
  padding: 1em;
  text-align: center;
  
`
export const HeaderCol = styled.div`
  padding: 1em;
  text-align: center;
  display: table-cell;
  background: var(--color-table-header-background);
  color: var(--color-table-header-text);
  font-size: 1em;
  font-weight: 600;
  &:first-child{
    ${(props) => props.dir === "rtl"?`border-top-right-radius:var(--color-table-border-radius);`:`border-top-left-radius:var(--color-table-border-radius);`}
    
  }
  &:last-child{
    ${(props) => props.dir === "rtl"?`border-top-left-radius:var(--color-table-border-radius);`:`border-top-right-radius:var(--color-table-border-radius);`}
  }
`

export const Value = styled.span`
  ${(props) => props.dir && `
    direction : ${props.dir} !important;
  `};
  
`
export const GreenValue = styled.span`
  color: #1dee19;
`
export const RedValue = styled.span`
  color: #ff5e5a;
`
export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & a{
    margin: 0 .5em;
    padding: .4em .8em;
    border:1px solid var(--color-text);
    color: var(--color-text);
    &:hover{
      background: var(--color-primary);
      border-color: var(--color-primary);
      color: var(--color-secondary-on2)
    }
  }
  
`
export const ActionSpinner = styled(Spinner)`
  
`
export const Action = styled(NavLink)`
  border-radius: var(--form-element-border-radius);
  & .spinner{
    margin: 0 .2em;
  }
  text-decoration: none !important;
  ${(props) => props.color && `
    color: ${props.color} !important;
    border-color: ${props.color} !important;
    & .spinner{
        color: ${props.color} !important;
    }
    &:hover{
        color: #fff !important;
        border-color: ${props.color} !important;
        background: ${props.color} !important;
    }
    &:hover > .spinner{
        color: #fff !important;
    }
  `};
  ${(props) => props.disabled && `
        opacity: .6;
  `}
  
  
`
export const Pagination = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 2em;
  padding: 0;
  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
    margin: 2em 0;
  }
`
export const Page = styled.li`
  padding: .5em 1em;
  margin: 0 .2em;
  background: var(--color-table-pagination-button-background);
  color: var(--color-table-pagination-button-text);
  border: 1px solid var(--color-table-pagination-button-border);
  ${(props) => (!props.disabled && !props.current) && `&:hover{
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
    color: #333;
    transition: all ease-in-out .3s;
  }`}
  ${(props) => props.button && `
    background: var(--color-table-pagination-navigate-background);
    color: var(--color-table-pagination-navigate-text);
    border-color: var(--color-table-pagination-navigate-border);
      ${(!props.disabled && !props.current)?`
        &:hover{
          border: 1px solid var(--color-primary);
          background: var(--color-primary);
          color: #333;
        }
      `:``}
  `}
  min-width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  ${(props) => props.disabled && `
    background: var(--color-table-pagination-button-disabled-background);
    border-color: var(--color-table-pagination-button-disabled-border);
    color: var(--color-table-pagination-button-disabled-text);
    cursor: initial;
  `}
  ${(props) => props.current && `
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: #333;
    cursor: initial;
  `}
    @media screen and (max-width: 700px) {
      flex: 1 0 calc(25% - .4em);
      margin-top: .3em;
      &.btn-navigate{
        flex: 1 0 calc(50% - .4em);
      }
      &.btn-more{
        flex: 1 0 calc(100% - .4em);
      }
    }
`
export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`
export const NotFoundWrapper = styled.div`
  & p{
    text-align: center;
    padding: 1em;
    color: #adadad;
    font-size: 1.2em;
    font-weight: 600;
  }
`
export const Sort = styled.div`
  font-size: 12px;
  margin: 0;
  position: absolute;
  right: -8px;
  top: -2px;
  & svg{
    display: block;
  }
`
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const SortIcon = styled.div`
  cursor: pointer;
  &:hover{
    color: var(--color-primary);
    transition: all ease-in-out .3s;
  }
  ${(props) => props.active && `
    color: var(--color-primary);
  `}
`